import React from "react";

export const SuccessBigIcon = () => {
  return (
    <svg
      width="116"
      height="116"
      viewBox="0 0 116 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.5738 39.0884L50.6503 74.012L34.7759 58.1376M113.825 58C113.825 88.4309 89.1559 113.1 58.725 113.1C28.2941 113.1 3.625 88.4309 3.625 58C3.625 27.5691 28.2941 2.90002 58.725 2.90002C89.1559 2.90002 113.825 27.5691 113.825 58Z"
        stroke="#1EB352"
        stroke-width="4.35"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
