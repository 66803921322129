import {
    SET_CUSTOMER_INFO,
    UPLOAD_COMPANY_INFO,
    SHOW_SIDE_DRAWER,
    SAVE_SETTINGS,
    SAVE_MARKET_HOURS,
    ON_START_MARKET,
    SET_PERIOD,
    SET_PAGE_SIZE,
    SET_CHANGE_DATE,
    SET_PREV_NOTIF_COUNT,
    SET_NOTIF_COUNT,
    SAVE_FLOW_DATA,
    RESET_FLOW_DATA,
    FLOW_DATA_ALL_STEPS_VISITED,
} from '../actions';
import { AnyAction } from 'redux'
import { fieldsGeneral, fieldsUser, fieldsCounts, fieldsCommision } from "../../constants/data"
import { FORMAT } from "../../constants"
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);

const initialState = {
    customerData: {
        general: fieldsGeneral,
        users: fieldsUser,
        // billingInfo: fieldsBillingInfo,
        counts: fieldsCounts,
        commision: fieldsCommision
    },
    showSideDrawer: false,
    settings: {
        activeServiceMode: false,
        // currentLang: "ro",
        comissionForBuy: "",
        comissionForSale: "",
    },
    startMarketHours: {
        startHours: "",
        endHours: "",
    },
    checkedStartMarket: false,
    period: 3,
    terminalPage: 10,
    merchantPage: 10,
    transactionPage: 10,
    sellersPage: 10,
    disputPage: 10,
    dateFrom: dayjs().startOf("day").startOf("quarter").format(FORMAT),
    dateTo: dayjs().endOf("day").format(FORMAT),
    notificationCount: 0,
    prevNotificationCount: 0,
    flowData: {
        merchant: [],
        stores: [],
        terminals: [],
        sellers: [],
        allStepsVisited:false
    }
};

export default function addressesReducer(state = initialState, action: AnyAction) {
    switch (action.type) {

        case SAVE_MARKET_HOURS:
            return {
                ...state,
                startMarketHours: {
                    ...action.payload
                }
            }
        case RESET_FLOW_DATA:
            return {
                ...state,
                flowData: {
                    merchant: [],
                    stores: [],
                    terminals: [],
                    sellers: [],
                    allStepsVisited: false,
                }
            }

            
        case FLOW_DATA_ALL_STEPS_VISITED:
            return {
                ...state,
                flowData: {
                    ...state.flowData,
                    allStepsVisited: true,
                }
            }
        case SAVE_FLOW_DATA:
            // if (!action.payload.stateData) {
            //     return {
            //         ...state,
            //         flowData: {
            //             ...state.flowData,
            //             [action.payload.stateName]: []
            //         }
            //     }
            // } else 

            if (action.payload.stateName === "merchant") {

                return {
                    ...state,
                    flowData: {
                        ...state.flowData,
                        [action.payload.stateName]: [
                            ...state.flowData[action.payload.stateName],
                            action.payload.stateData
                        ]
                    }
                }
            } else {
                const arr: string[] = [];
                const findIndex = state.flowData[action.payload.stateName].findIndex(
                    (x) => x.id === action.payload.deletedItemId
                );
                
                if (findIndex < 0) {
                    arr.push(...state.flowData[action.payload.stateName], action.payload.stateData);
                } else {
                    const newArr = [
                        ...state.flowData[action.payload.stateName].slice(0, findIndex),
                        ...state.flowData[action.payload.stateName].slice(findIndex + 1),
                    ];
                    arr.push(...newArr);
                }

                return {
                    ...state,
                    flowData: {
                        ...state.flowData,
                        [action.payload.stateName]: arr
                    }
                }
            }

        case SET_PAGE_SIZE:
            return {
                ...state,
                [action.payload.page]: action.payload.size
            }
        case SET_CHANGE_DATE:
            console.log("action.payload.dateKey222", action.payload.dateValue)
            return {
                ...state,
                [action.payload.dateKey]: action.payload.dateValue
            }
        case ON_START_MARKET:
            return {
                ...state,
                checkedStartMarket: action.payload
            }

        case SET_PERIOD:
            return {
                ...state,
                period: action.payload
            }
        case SET_PREV_NOTIF_COUNT:
            return {
                ...state,
                prevNotificationCount: action.payload
            }
        case SET_NOTIF_COUNT:
            return {
                ...state,
                notificationCount: action.payload
            }
        case UPLOAD_COMPANY_INFO:
            return {
                ...state,
                customerData: {
                    ...state.customerData,
                    ...action.payload
                }
            }
        case SHOW_SIDE_DRAWER:
            return {
                ...state,
                showSideDrawer: !state.showSideDrawer
            }
        case SAVE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...action.payload
                }
            }

        case SET_CUSTOMER_INFO:
            const block = action.payload.block;
            const stateName = action.payload.stateName;
            return {
                ...state,
                customerData: {
                    ...state.customerData,
                    [block]: {
                        ...state.customerData[block],
                        values: {
                            ...state.customerData[block].values,
                            [stateName]: action.payload.value
                        }
                    }


                }
            }

        default:
            return state;
    }
}