/* eslint-disable react-hooks/exhaustive-deps */
import cn from "classnames";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Button from "../../infrastructure/components/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { enqueueSnackbar } from "notistack";
import Checkbox from "@mui/material/Checkbox";
import { SuccessBigIcon } from "../../svg-icons/icon-success-big";
import { CirclePlusIcon } from "../../svg-icons/icon-plus-circle";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../../api/services/Api";
import { useSelector, useDispatch } from "react-redux";
import { getFlowData } from "../../api/selectors/mainSelector";

import styles from "./merchant-flow-result-screen.module.scss";
import { resetFlowData, flowDataAllStepsVisited } from "../../api/actionCreators/mainActionCreator";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MerchantFlowResultScreen = ({ flowMode, setActiveStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showShareLoginDataModal, setShowShareLoginDataModal] = useState(null);
  const [isSendMerchantInfo, setIssSendMerchantInfo] = useState(true);
  const [isSendStoreInfo, setIsSendStoreInfo] = useState(true);
  const [isSendSellerInfo, setIsSendSellerInfo] = useState(true);
  const [isSendTerminalInfo, setisSendTerminalInfo] = useState(true);
  const [isSendEcommCredentials, setIsSendEcommCredentials] = useState(false);
  const [isSendingCredentials, setIsSendingCredentials] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const inFlowData = useSelector(getFlowData);

  const resetCheckboxes = () => {
    setIssSendMerchantInfo(true);
    setIsSendStoreInfo(true);
    setIsSendSellerInfo(true);
    setisSendTerminalInfo(true);
    setIsSendEcommCredentials(inFlowData.merchant[0]?.merchantInfo?.isEnabledEcomm || false);
  };

  useEffect(() => {
    setIsSendEcommCredentials(inFlowData.merchant[0]?.merchantInfo?.isEnabledEcomm || false)
  },[])

  useEffect(() => {
    dispatch(flowDataAllStepsVisited())
  },[])

  const handleRedirect = (shouldRedirect) => {
    setTimeout(() => {
      setRedirect(shouldRedirect);
    }, 2000);
  };

  const sendCredentials = async () => {
    setIsSendingCredentials(true);

    try {
      const payload = {
        includeMerchantInfo: isSendMerchantInfo,
        includeStoreInfo: isSendStoreInfo,
        includeSellerInfo: isSendSellerInfo,
        includeTerminalInfo: isSendTerminalInfo,
        includeEcommCredentials: isSendEcommCredentials,
      };

      const { status, response } = await apiRequest(
        "post",
        `/sap/api/v1/merchant/${inFlowData.merchant[0].merchantId}/share`,
        payload
      );

      if (status === 200 || status === 201) {
        switch (response.data.result) {
          case "success":
            enqueueSnackbar(t("Credentials_successfully_sended"), {
              variant: "success",
            });
            break;
          case "merchant_not_found":
            enqueueSnackbar(t("Merchant_not_found"), { variant: "error" });
            break;
          case "cap_user_not_found":
            enqueueSnackbar(t("cap_user_not_found"), { variant: "error" });
            break;
          case "details_not_selected":
            enqueueSnackbar(t("details_not_selected"), { variant: "error" });
            break;
          default:
            enqueueSnackbar(
              t("Error_200_start_message") +
                response.data.result +
                t("Error_200_end_message"),
              { variant: "error" }
            );
            break;
        }
      } else {
        switch (status) {
          case 400:
            enqueueSnackbar(t("Error_400"), { variant: "error" });
            break;
          case 401:
            enqueueSnackbar(t("Error_401"), { variant: "error" });
            handleRedirect(false);
            setTimeout(() => window.location.reload(), 2000);
            break;
          case 404:
            enqueueSnackbar(t("Error_404"), { variant: "error" });
            handleRedirect(true);
            break;
          case 500:
          case 501:
          case 502:
          case 503:
          case 504:
          case 505:
            enqueueSnackbar(t("Error_500"), { variant: "error" });
            handleRedirect(true);
            break;
          default:
            enqueueSnackbar(t("Unexpected_error_status") + `: ${status}`, {
              variant: "error",
            });
            break;
        }
      }
    } catch (error) {
      console.error("Failed to update cap-user:", error);
    } finally {
      setIsSendingCredentials(false);
      setShowShareLoginDataModal(false);
      resetCheckboxes();
    }
  };

  const renderShareLoginDataModal = () => {
    // Check if at least one checkbox is selected
    const isAtLeastOneChecked =
      isSendMerchantInfo ||
      isSendStoreInfo ||
      isSendSellerInfo ||
      isSendTerminalInfo ||
      isSendEcommCredentials;

    return (
      <Dialog
        open={showShareLoginDataModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setShowShareLoginDataModal(false);
          resetCheckboxes();
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Share_login_data_title")}
          </DialogTitle>

          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Share_ecomm_data_text") + " " + inFlowData?.merchant[0]?.capUserInfo.email} 
            </DialogContentText>
            <div className={styles.checkbox_wrapper}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSendMerchantInfo}
                      onChange={(e) => setIssSendMerchantInfo(e.target.checked)}
                    />
                  }
                  label={t("Share_merchant_data_checkbox")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSendStoreInfo}
                      onChange={(e) => setIsSendStoreInfo(e.target.checked)}
                    />
                  }
                  label={t("Share_store_data_checkbox")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSendSellerInfo}
                      onChange={(e) => setIsSendSellerInfo(e.target.checked)}
                    />
                  }
                  label={t("Share_seller_data_checkbox")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSendTerminalInfo}
                      onChange={(e) => setisSendTerminalInfo(e.target.checked)}
                    />
                  }
                  label={t("Share_terminal_data_checkbox")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSendEcommCredentials}
                      onChange={(e) =>
                        setIsSendEcommCredentials(e.target.checked)
                      }
                    />
                  }
                  label={t("Share_ecomm_data_checkbox")}
                />
              </FormGroup>
            </div>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => sendCredentials()}
              label={t("Send")}
              variant={"ghost"}
              className={cn({
                [styles.disabled_btn]:
                  isSendingCredentials || !isAtLeastOneChecked,
                [`${styles[`btn_change`]}`]: true,
              })}
              icon={
                isSendingCredentials && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
              disabled={!isAtLeastOneChecked || isSendingCredentials}
            />
            <Button
              onClick={() => {
                setShowShareLoginDataModal(false);
                resetCheckboxes();
              }}
              variant="ghost"
              label={t("Cancel")}
            // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const onAddNewMerchant = () => {
    dispatch(resetFlowData())
    setActiveStep(0);
  }

  return (    
    <AppMainLayout navBarTitle={t("Reports")} flowMode={flowMode} noBackground>
      {renderShareLoginDataModal()}
      <div className={styles.section}>
        <div className={styles.container}>
          <SuccessBigIcon />
          <span className={styles.title}>
            {t("Merchant_successfully_added")}
          </span>
          <span className={styles.text}>{t("Merchant_flow_result_text")}</span>
        </div>
        <div className={styles.btn_container}>
          <Link to="/app/merchants" className={styles.no_underline}>
            <Button
              label={"OK"}
              variant="primary"
              className={styles.btn_primary}
            />
          </Link>
          <Button
            label={t("Share")}
            variant="primary"
            onClick={() => setShowShareLoginDataModal(true)}
            className={styles.btn_secondary}
          />
        </div>
        {/* <Link to="/app/merchants/new" className={styles.no_underline}> */}
          <div className={styles.btn_add_new_container} onClick={() => onAddNewMerchant()}>
            <CirclePlusIcon />
            <span>{t("Add_new_merchant")}</span>
          </div>
        {/* </Link> */}
      </div>
    </AppMainLayout>
  );
};

export default MerchantFlowResultScreen;
