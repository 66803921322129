/* eslint-disable @typescript-eslint/no-unused-expressions */
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { SaveIcon } from "finergy-kit";
import { apiRequest } from "../../api/services/Api";
import { useTranslation } from "react-i18next";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import TextField from "@mui/material/TextField";
import { Redirect, Link } from "react-router-dom";
import classNames from "classnames";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { getAccessToken } from "../../api/selectors/tokenSelector";
import { ResetIcon } from "../../svg-icons/icon-reset";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DeleteIcon } from "../../svg-icons/icon-delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../infrastructure/components/Button";
import { VALIDATION } from "../../constants";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { getFlowData } from "../../api/selectors/mainSelector";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import {
  saveFlowData
} from "../../api/actionCreators/mainActionCreator";

import styles from "./store-details.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StoreDetails = ({
  flowMode = false,
  setStepResult
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const defaultStore = {
    storeName: "",
    storeAddress: "",
    comment: "",
    whenCreated: "",
    merchantId: { id: "", merchantName: "" },
  };
  const editMode = storeId !== "new";
  const [isFetching, setIsFetching] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [storeInfo, setStoreInfo] = useState({ ...defaultStore });
  const [storesInflow, setStoresInflow] = useState([]);
  const [invalidFields, setInvalidFields] = useState([]);
  const [hardBlock, setHardBlock] = useState(false);
  const [merchatsList, setMerchatsList] = useState([]);
  const [oldStoreInfo, setOldStoreInfo] = useState({
    ...defaultStore,
  });
  const [deleteStore, setDeleteStore] = useState(null);
  const [fetchMerchants, setFetchMerchants] = useState(true);
  const accessToken = useSelector(getAccessToken);
  const inFlowData = useSelector(getFlowData);
  // console.log("storeInfo", storeInfo)
  useEffect(() => {
    const fetch = flowMode ? false : storeId !== "new";
    setIsFetching(fetch);
  }, [storeId]);


  const fetchStores = async () => {
    setIsFetching(true);
    try {
      const {
        response,
        response: { status },
      } = await apiRequest(
        "get",
        `/sap/api/v1/store?offset=0&count=1000&filter=&merchantId=${inFlowData.merchant[0].merchantId || ""
        }`,
        null
      );

      if (status === 200 || status === 201) {
        const {
          data: {
            storeList,
          },
        } = response;

        setStoresInflow(storeList || []);

        setFetchMerchants(true);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch terminals:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (inFlowData?.merchant[0]?.merchantId) {
      const data = {
        ...storeInfo,
        merchantId: { id: inFlowData.merchant[0].merchantId, merchantName: inFlowData.merchant[0]?.merchantInfo?.merchantName },
      };
      setStoreInfo(data);
    }
  }, [])

  useEffect(() => {
    if (inFlowData.stores.length > 0 || flowMode) {
      fetchStores()
    }
    setStoreInfo({ ...defaultStore, merchantId: { id: inFlowData?.merchant[0]?.merchantId || "", merchantName: inFlowData?.merchant[0]?.merchantInfo?.merchantName || "" } });
  }, [inFlowData.stores])

  console.log("inFlowData", inFlowData);

  const getStoreInfo = async () => {
    setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "get",
        ` /sap/api/v1/store/${storeId}`,
        null
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        setStoreInfo({ ...defaultStore, ...data, merchantId: { id: data.merchantId, merchantName: data.merchantName } });
        setOldStoreInfo({ ...defaultStore, ...data, merchantId: { id: data.merchantId, merchantName: data.merchantName } });
        setFetchMerchants(true)
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to fetch store info:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const getMerchatsList = async () => {
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/merchant/brief`,
        null
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        setMerchatsList(data.merchantList);

      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to fetch merchants list:", error);
    }
  };

  useEffect(() => {
    accessToken && editMode && !flowMode && getStoreInfo();
    !editMode && setFetchMerchants(true);
  }, [accessToken, storeId, editMode]);

  useEffect(() => {
    fetchMerchants && getMerchatsList();
  }, [fetchMerchants]);

  const validateInput = () => {
    const result = [];

    VALIDATION.ADD_STORE_FORM.forEach((field) => {
      if (field === "merchantId") {
        !flowMode && (!storeInfo[field] || storeInfo[field]?.id === "") && result.push(field);
      } else {
        !storeInfo[field] && result.push(field);
      }

    });
    return result.length > 0 ? result : null;
  };

  const createStore = async () => {
    setIsFetching(true);
    const params = {
      storeName: storeInfo.storeName,
      storeAddress: storeInfo.storeAddress,
      comment: storeInfo.comment,
      merchantId: storeInfo.merchantId?.id,
    };

    const invalidFields = validateInput();

    setInvalidFields(invalidFields);
    invalidFields &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    invalidFields && setIsFetching(false);
    console.log("invalidFields", invalidFields)
    try {
      if (!invalidFields) {
        const { status, response } = await apiRequest(
          "post",
          `/sap/api/v1/store`,
          params
        );

        if (status === 200 || status === 201) {

          if (response.data.result === "success") {

            enqueueSnackbar(t("Store_successfully_created"), {
              variant: "success",
            });

            if (!flowMode) {
              setRedirect(true);
            } else {
              dispatch(saveFlowData("stores", {id: response.data.storeId, ...storeInfo}))
              setStepResult(false);
              //setIsFetching(true);
            }
          } else {
            enqueueSnackbar(
              t("Error_200_start_message") +
              response.data.result +
              t("Error_200_end_message"),
              { variant: "error" }
            );
            setHardBlock(true);
            setRedirect(true);
          }

        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      }
    } catch (error) {
      console.error("Failed to create store:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const updateStore = async () => {
    setIsFetching(true);
    const invalidFields = validateInput();
    setInvalidFields(invalidFields);
    invalidFields &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    invalidFields && setIsFetching(false);

    const params = {
      storeName: storeInfo.storeName,
      storeAddress: storeInfo.storeAddress,
      merchantId: storeInfo.merchantId.id,
      comment: storeInfo.comment,
    };

    try {
      if (!invalidFields) {
        const { status } = await apiRequest("put", `/sap/api/v1/store/${Number(storeId)}`, params);
        if (status === 200 || status === 201) {
          enqueueSnackbar(t("Store_successfully_updated"), {
            variant: "success",
          });
          setHardBlock(true);
          setRedirect(true);
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Failed to update store:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteStoreID = async () => {
    setIsDeleting(true);
    const params = {};
    try {
      const { status, response } = await apiRequest(
        "delete",
        `/sap/api/v1/store/${storeId || deleteStore}`,
        params
      );

      if (status === 200 || status === 201) {

        if (response.data.result === "success") {
          enqueueSnackbar(t("Store_successfully_deleted"), {
            variant: "success",
          });
          if (!flowMode) {
            setDeleteStore(null);
            setRedirect(true);
          } else {
            dispatch(saveFlowData("stores", [], storeId || deleteStore))
            setStepResult(false);
            //setIsFetching(true);
          }
        } else {
          enqueueSnackbar(
            t("Error_200_start_message") +
            response.data.result +
            t("Error_200_end_message"),
            { variant: "error" }
          );
          setRedirect(false);
        }


      } else if (status === 204) {
        enqueueSnackbar(t("Store_successfully_deleted"), {
          variant: "success",
        });
        if (!flowMode) {
          setRedirect(true);

        } else {
          setStepResult(false);
          //setIsFetching(true);
          dispatch(saveFlowData("stores", [], storeId || deleteStore))
        }

      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to delete store:", error);
    } finally {
      setDeleteStore(null);
      setIsDeleting(false);
    }
  };

  const removeError = (stateName) => {
    if (invalidFields && invalidFields.length > 0) {
      const fIndex =
        invalidFields && invalidFields.findIndex((e) => e === stateName);
      const errorListFields = [
        ...invalidFields?.slice(0, fIndex),
        ...invalidFields?.slice(fIndex + 1),
      ];

      setInvalidFields(errorListFields || []);
    }
  };

  const onChange = (state, value) => {
    const data = {
      ...storeInfo,
      [state]: value,
    };

    setStoreInfo(data);
  };

  const navBarTitle = oldStoreInfo.storeName || t("New_store");

  const disabledButton =
    JSON.stringify(storeInfo) === JSON.stringify(oldStoreInfo);

  const breadcrumbs = {
    step1Label: t("Stores"),
    step1Url: "/app/stores",
    step2Label: `${oldStoreInfo.storeName || t("Add_new_store")}`,
  };
  // console.log("oldStoreInfo", storeInfo, oldStoreInfo)
  const renderDeleteModal = () => {
    return (
      <Dialog
        open={!!deleteStore}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteStore(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Are_you_sure_delete_store")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Are_you_sure_delete_store_description")} #{deleteStore}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => (isDeleting ? {} : deleteStoreID())}
              label={t("Delete")}
              variant="delete"
              disabled={isDeleting}
              className={cn({
                [styles.disabled_btn]: isDeleting,
              })}
              // iconPosition="off"
              icon={
                isDeleting && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
            // iconOnly={isDeleting}
            // id={"DELETE_BUTTON"}
            />
            <Button
              onClick={() => setDeleteStore(null)}
              variant="ghost"
              label={t("Cancel")}
            // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };


  const renderStoresCells = () => {
    return [
      {
        field: "id",
        headerName: t("Store_id"),
        flex: 0.3,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <span className={styles.no_underline}>
              {row.id}
            </span>
          );
        },
      },
      {
        field: "storeName",
        headerName: t("Store_name"),
        flex: 0.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <span className={styles.no_underline}>
              {row.storeName}
            </span>
          );
        },
      },
      {
        field: "storeAddress",
        headerName: t("Address"),
        flex: 0.6,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_address,
        renderCell: ({ row }) => {
          return <span className={styles.no_color}>{row.storeAddress}</span>;
        },
      },
      {
        field: "whenCreated",
        headerName: t("Registration_date"),
        cellClassName: styles.table_cell_date,
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return (
            <span className={styles.no_color}>
              {moment(row.whenCreated).format("DD/MM/YYYY, HH:mm")}
            </span>
          );
        },
      },
      {
        field: "actions",
        headerName: "",
        flex: 0.1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return <DeleteIcon onClick={() => setDeleteStore(row.id)} />
        },
      },
    ];
  };

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle, flowMode }}>
      {renderDeleteModal()}
      <div>
        {redirect && !flowMode && <Redirect to={"/app/stores/"} />}
        <div
          className={classNames({
            [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <>
              <div className={styles.left_forms}>
                <div
                  className={classNames({
                    [styles.info_block]: true,
                    [styles.flow_info_block]: flowMode,
                  })}
                >
                  <div className={styles.info_block_title}>
                    <span>{t("General")}</span>
                    {storeInfo.whenCreated && (
                      <span className={styles.creation_date}>
                        {t("Registration_date")}:{" "}
                        {moment(storeInfo.whenCreated).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </span>
                    )}
                  </div>

                  <div className={styles.info_block_content}>
                    {editMode && !flowMode && (
                      <div
                        className={classNames({
                          [styles.info_block_input_container]: true,
                        })}
                      >
                        <label>{t("Store_id")} </label>
                        <div className={styles.block_value}>{storeId}</div>
                      </div>
                    )}

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="storeId">{t("Store_name")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Store_name")}
                        type={"text"}
                        id="storeName"
                        value={storeInfo.storeName}
                        onChange={(e) => onChange("storeName", e.target.value)}
                        onFocus={() => removeError("storeName")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "storeName") >
                          -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "storeName") > -1
                        }
                      />
                    </div>

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="storeId">{t("Address")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Address")}
                        type={"text"}
                        id="storeAddress"
                        value={storeInfo.storeAddress}
                        onChange={(e) =>
                          onChange("storeAddress", e.target.value)
                        }
                        onFocus={() => removeError("storeAddress")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "storeAddress") >
                          -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "storeAddress") >
                          -1
                        }
                      />
                    </div>


                    {!flowMode && <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="storeId">{t("Merchant_id")}</label>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo"
                          options={merchatsList}
                          autoHighlight
                          getOptionLabel={(option) => option.id && `#${option.id} ${option.merchantName}`}
                          value={storeInfo.merchantId || { id: "" }}
                          onChange={(event, newValue) => {
                            onChange("merchantId", newValue)
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              #{option.id} {option.merchantName}
                            </Box>
                          )}
                          className={classNames({
                            [styles.autocomplete]: true,
                            [styles.autocomplete_error]: invalidFields?.length > 0 && invalidFields.findIndex((i) => i === "merchantId") > -1,
                          })}
                          onFocus={() => removeError("merchantId")}
                          disabled={editMode || flowMode}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Merchant_id")}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                              helperText={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex((i) => i === "merchantId") >
                                -1 &&
                                t("Required_field")
                              }
                              error={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex((i) => i === "merchantId") >
                                -1
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </div>}

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="comment">{t("Comment")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Comment")}
                        type={"text"}
                        multiline
                        rows={3}
                        id="comment"
                        value={storeInfo.comment}
                        onChange={(e) => onChange("comment", e.target.value)}
                      />
                    </div>

                  </div>
                </div>
              </div>

              {flowMode && <div className={styles.right_forms}>
                <div
                  className={classNames({
                    [styles.info_block]: true,
                    [styles.info_block_flow_mode]: flowMode,
                  })}
                >
                  <div className={styles.info_block_title}>
                    <span>{t("General")}</span>
                  </div>

                  <div className={styles.info_block_content}>
                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label>{t("Merchant_id")} </label>
                      <div className={styles.block_value}>#{inFlowData.merchant[0]?.merchantId}</div>
                    </div>

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label>{t("Merchant_name")} </label>
                      <div className={styles.block_value}>{inFlowData.merchant[0]?.merchantInfo?.merchantName}</div>
                    </div>


                  </div>
                </div>
              </div>}
            </>
          )}
        </div>


        {!isFetching && (
          <div className={styles.actions_buttons}>
            <div>
              {editMode && !flowMode && (
                <Button
                  variant="delete"
                  icon={<DeleteIcon onClick={() => { }} />}
                  className={styles.delete_button}
                  label={t("Delete")}
                  onClick={() => setDeleteStore(storeId)}
                />
              )}
            </div>
            <div>

              {oldStoreInfo.storeName && !flowMode ? (
                <Button
                  variant="ghost"
                  icon={<ResetIcon />}
                  disabled={disabledButton || isFetching || hardBlock}
                  className={classNames({
                    // [styles.save_button]: true,
                    [styles.reset_button]: true,
                  })}
                  onClick={() => setStoreInfo(oldStoreInfo)}
                  label={t("Reset")}
                />
              ) : !flowMode && (
                <Link to="/app/stores" className={styles.no_underline}>
                  <Button
                    variant="ghost"
                    className={styles.reset_button}
                    // icon={<DeleteIcon onClick={() => { }} />}
                    // disabled
                    label={t("Cancel")}
                  />
                </Link>
              )}
              <Button
                variant="primary"
                icon={<SaveIcon />}
                disabled={disabledButton || isFetching || hardBlock}
                // className={classNames({
                //   [styles.save_button]: true,
                //   [styles.disabled]: disabledButton,
                //   [styles.save_terminal_button]: true,
                // })}
                label={t("Save")}
                onClick={() =>
                  !oldStoreInfo.storeName
                    ? createStore()
                    : disabledButton
                      ? {}
                      : updateStore()
                }
              />
            </div>




          </div>
        )}
        <br />
        {flowMode && storesInflow.length > 0 &&
          <div className={styles.flow_added}>
            <div className={styles.flow_added_container}>
              {t("Added_stores")}
            </div>
            <DataTableComponent
              checkboxSelection={false}
              columns={renderStoresCells()}
              isFetching={false}
              idChangeValue={"id"}
              className={styles.terminal_table}
              rows={storesInflow}
            />
          </div>
        }
      </div>
    </AppMainLayout>
  );
};

export default StoreDetails;
