/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  getRefreshToken,
  getAccessToken,
} from "../../api/selectors/tokenSelector";
import Pagination from "../../infrastructure/components/Pagination";
import Button from "../../infrastructure/components/Button";

import { PlusIcon } from "../../svg-icons/icon-plus";

import styles from "./merchants.module.scss";
import { setPageSize } from "../../api/actionCreators/mainActionCreator";
import { getMerchantPageSize } from "../../api/selectors/mainSelector";
import { pages } from "../../constants";
import useDebounce from '../../hooks/useDebounce';
import { enqueueSnackbar } from "notistack";
import { apiRequest } from "../../api/services/Api";

const Merchants = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);
  const [searchText, setSearchText] = useState("");
  // const [redirectId, setRedirectId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  //   const [filter, setFilter] = useState("all");
  const [totalCount, setTotalCount] = useState(0);

  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);
  const pageSize = useSelector(getMerchantPageSize);

  const fetchMerchants = useCallback(async () => {
    setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/merchant?offset=${currentPage * pageSize
        }&count=${pageSize}&filter=${searchText}`,
        null
      );

      if (status === 200 || status === 201) {
        const {
          data: {
            merchantList,
            pagination: { totalCount },
          },
        } = response;
        setRows(merchantList || []);
        setTotalCount(totalCount);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch merchants:", error);
    } finally {
      setIsFetching(false);
    }
  }, [searchText, currentPage, pageSize]);

  useDebounce(() => {
    fetchMerchants()
  }, [searchText, currentPage, pageSize, fetchMerchants, token, accessToken], 800);


  const renderLicensesCells = () => {
    return [
      {
        field: "merchantId",
        headerName: t("Id_merchant"),
        flex: 0.4,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/merchants/${row.merchantId}`}
              className={styles.no_underline}
            >
              {row.merchantId}
            </Link>
          );
        },
      },
      {
        field: "merchantName",
        headerName: t("Name"),
        flex: 0.8,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/merchants/${row.merchantId}`}
              className={styles.no_underline}
            >
              {row.merchantName}
            </Link>
          );
        },
      },
      {
        field: "capUserFullName",
        headerName: t("Admin"),
        flex: 0.8,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/merchants/${row.merchantId}`}
              className={styles.no_underline}
            >
              {row.capUserFullName}
            </Link>
          );
        },
      },
      {
        field: "whenCreated",
        headerName: t("Registration_date"),
        flex: 2,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => {
          return moment(row.whenCreated).format("DD/MM/YYYY HH:mm");
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setIsFetching(true);
    setCurrentPage(0);
    dispatch(setPageSize(pages.MERCHANT_PAGE, size));
  };

  return (
    <AppMainLayout navBarTitle={t("Merchants")}>
      {/* {(redirectId === 0 || redirectId > 0) && (
        <Redirect to={`/app/merchants/${redirectId}`} />
      )} */}


      <div className={styles.customer_page_bottom_container}>
        <FormControl fullWidth sx={{}}>
          <InputLabel htmlFor="outlined-adornment-amount">
            {t("Search")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<SearchIcon />}
            label={t("Search")}
            value={searchText}
            onChange={(e) => { setCurrentPage(0); setSearchText(e.target.value) }}
          />
        </FormControl>

        <Link to="/app/merchants/new" className={styles.no_underline}>
          <Button
            variant="primary"
            icon={<PlusIcon />}
            label={t("New_merchant")}
            disabled={isFetching}
          />
        </Link>
      </div>
      <DataTableComponent
        // onCellClick={(row) => onCellClick(row)}
        checkboxSelection={false}
        columns={renderLicensesCells()}
        isFetching={isFetching}
        idChangeValue={"merchantId"}
        {...{ rows }}
      />
      {!isFetching && (
        <Pagination
          onClick={paginationClick}
          onChangePageSize={pageSizeClick}
          total={totalCount}
          pageIndex={currentPage}
          pageSize={pageSize}
        />
      )}


    </AppMainLayout>
  );
};

export default Merchants;
