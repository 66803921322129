import React from "react";

export const CirclePlusIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 5C9.95 5 9.5 5.45 9.5 6V9H6.5C5.95 9 5.5 9.45 5.5 10C5.5 10.55 5.95 11 6.5 11H9.5V14C9.5 14.55 9.95 15 10.5 15C11.05 15 11.5 14.55 11.5 14V11H14.5C15.05 11 15.5 10.55 15.5 10C15.5 9.45 15.05 9 14.5 9H11.5V6C11.5 5.45 11.05 5 10.5 5ZM10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM10.5 18C6.09 18 2.5 14.41 2.5 10C2.5 5.59 6.09 2 10.5 2C14.91 2 18.5 5.59 18.5 10C18.5 14.41 14.91 18 10.5 18Z"
        fill="#8C969B"
      />
    </svg>
  );
};
