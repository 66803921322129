import React, { useState, useRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Logo } from "../../../assets/icons/logo";

import { INavigationComponentProps } from "./types";
import classNames from "classnames";
import { navigation } from "../../../constants/data";
import styles from "./navigation.module.scss";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import TextField from "@mui/material/TextField";
// import { ExitIcon } from "../../../svg-icons/icon-exit";
import { useTranslation } from "react-i18next";
// import { getIsAuthFetching } from "../../../api/selectors/authSelector";
import { getNotificationCount, getPrevNotificationCount } from "../../../api/selectors/mainSelector";
import {
  setPrevNotificationCount,
  setNotificationCount,
} from "../../../api/actionCreators/mainActionCreator";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Button } from "finergy-kit";
import CircularProgress from "@mui/material/CircularProgress";
import { enqueueSnackbar } from "notistack";
import AirIcon from '@mui/icons-material/Air';
// import { CloseRemoveIcon } from "../../../svg-icons/icon-close-remove";
// import InputMask from "react-input-mask";
// import axios from "../../../api/services/Api";
// import moment from "moment";

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>;
//   },
//   ref: React.Ref<unknown>
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

function NavigationComponent({ className }: INavigationComponentProps) {
  const { t } = useTranslation();

  // const isAuthFetching = useSelector(getIsAuthFetching);
  const notificationCount = useSelector(getNotificationCount);
  const prevNotificationCount = useSelector(getPrevNotificationCount);
  const dispatch = useDispatch();

  // const [open, setOpen] = useState(false);
  // const [notificationCount, setNotificationCount] = useState(0);
  // const [prevNotificationCount, setPrevNotificationCount] = useState(0);
  const [time, setTime] = useState(0);
  const timerRef = useRef(null);

  const pathname = window.location.pathname;

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1); // Обновляем время каждые 10 миллисекунд
    }, 1000);

    return () => clearInterval(timerRef.current)
  }, [])


  const fetchDisputeCount = async () => {
    try {
      const source = axios.CancelToken.source();
      // const timeout = setTimeout(() => {
      //   source.cancel();
      // }, 10000);

      const response = await axios.get("/sap/api/v1/dispute/bank/count", {
        cancelToken: source.token,
      });

      const newNotificationCount = response.data;

      if (newNotificationCount !== prevNotificationCount) {
        dispatch(setPrevNotificationCount(notificationCount));
        dispatch(setNotificationCount(newNotificationCount));
      }
      setTime(0);
      // clearTimeout(timeout);
    } catch (error) {
      if (axios.isCancel(error)) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    }
  };

  useEffect(() => {
    time > 10 && fetchDisputeCount();
  }, [time]);

  // useEffect(() => {
  //   fetchDisputeCount();
  // }, []);
  //notificationCount, prevNotificationCount

  const renderMenu = () => {
    const content = navigation.map(({ local, url, icon, showNotification }, index) => {
      const selectedMenu = pathname.includes(url);
      return (
        <Link
          underline="none"
          className={classNames({
            [`${styles.nav_link_wrapper}`]: true,
            [`${styles.active_nav_menu}`]: selectedMenu,
          })}
          to={`${url}`}
          key={index}
        >
          <span className={styles.nav_icon}>{icon}</span>
          <div className={styles.nav_cta_link}>
            {t(local)}
            {showNotification && (prevNotificationCount > 0 || notificationCount > 0) && (
              <span className={styles.notification_circle}>
                {notificationCount !== 0
                  ? `+${notificationCount}`
                  : prevNotificationCount !== 0 || prevNotificationCount > 0
                    ? `+${prevNotificationCount}`
                    : ""}{" "}
              </span>
            )}
          </div>
        </Link>
      );
    });

    return content;
  };

  // const renderExitModal = () => {
  //   return (
  //     <Dialog
  //       open={open}
  //       TransitionComponent={Transition}
  //       keepMounted
  //       onClose={() => setOpen(false)}
  //       aria-describedby="alert-dialog-slide-description"
  //     >
  //       <div className={styles.custom_modal_container}>
  //         <DialogTitle className={styles.setting_modal_title}>
  //           {t("Logout")}
  //         </DialogTitle>
  //         <DialogContent className={styles.modal_content}>
  //           <DialogContentText
  //             id="alert-dialog-slide-description"
  //             className={styles.logout_header}
  //           >
  //             {t("Are_you_sure")}
  //           </DialogContentText>
  //         </DialogContent>
  //         <DialogActions className={styles.action_buttons}>
  //           <Button
  //             onClick={() => onExit()}
  //             variant="tertiary"
  //             label={t("Exit")}
  //             size="l"
  //             disabled={isAuthFetching}
  //             iconPosition="off"
  //             icon={
  //               <CircularProgress className="circular-progress" size={18} />
  //             }
  //             iconOnly={isAuthFetching}
  //             id={"BACK_BUTTON"}
  //             className={classNames({
  //               [`${styles.logout_button}`]: true,
  //             })}
  //           />
  //           <Button
  //             onClick={() => setOpen(false)}
  //             variant="tertiary"
  //             label={t("Cancel")}
  //             // disabled={isServiceModeFetching}
  //             className={styles.exit_button}
  //             size="l"
  //             iconPosition="off"
  //             id={"ON_EXIT"}
  //           />
  //         </DialogActions>
  //       </div>
  //     </Dialog>
  //   );
  // };
  const selectedMenu = pathname.includes("/flow");
  return (
    <div
      className={classNames({
        [`${styles.nav_wrapper}`]: true,
        [className]: true,
      })}
    >
      {/* {renderExitModal()} */}
      <div className={styles.nav_logo}>
        <Logo />
      </div>

      <div className={styles.divider} />

      <div>
        <div className={styles.nav_menu}>
          <Link
            underline="none"
            className={classNames({
              [`${styles.nav_link_wrapper}`]: true,
              [`${styles.active_nav_menu}`]: selectedMenu,
            })}
            to={`/app/flow`}
            key={999}
          >
            <span className={styles.nav_icon}><AirIcon /></span>
            <div className={styles.nav_cta_link}>
              {t("Merchant_flow")}
            </div>
          </Link>
          {renderMenu()}
        </div>
        {/* <div
          onClick={() => setOpen(true)}
          className={classNames({
            [`${styles.nav_link_wrapper}`]: true,
            [`${styles.nav_link_exit}`]: true,
          })}
        >
          <ExitIcon />
          <div>{t("Exit")}</div>
        </div> */}
      </div>

      <div className={styles.navbar_footer}>
        <div className={styles.block_item}>
          <div className={styles.support}>
            {" "}
            {t("Support")}: <a href="tel:+37322555544">+373 22 555 544</a>
          </div>
          <div className={styles.copyright}>
            {" "}
            {t("Build_by")}{" "}
            <a href="https://finergy.md/" target="_blank" rel="noreferrer">
              Finergy Tech.
            </a>
            <br /> {t("All_rights_reserved")}.
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationComponent;
