/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axios, { apiRequest } from "../../../api/services/Api";
import { useTranslation } from "react-i18next";
import AppMainLayout from "../../../infrastructure/layouts/AppMainLayout";
import classNames from "classnames";
import { UseErrorLabel } from "../../../hooks/useErrorLabel";
import { getAccessToken } from "../../../api/selectors/tokenSelector";
import TextField from '@mui/material/TextField';
import { isImageValid } from "../../../constants";
import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import styles from "./dispute-info.module.scss";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { DisputsIcon } from "../../../svg-icons/icon-disputs";
import { AttachFileClipIcon } from "../../../svg-icons/icon-attach-file-clip";
import { SendIcon } from "../../../svg-icons/icon-send-message";
import { CornerMyIcon } from "../../../svg-icons/icon-corner-my";
import { CornerBankIcon } from "../../../svg-icons/icon-corner-bank";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import CloseIcon from "@mui/icons-material/Close";
import { DownloadIcon } from "../../../svg-icons/icon-download"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DisputeInfo = ({ disputeId }) => {
  const { t } = useTranslation();
  // const { disputeId } = useParams();

  const defaultDesputeInfo = {
    disputeId: null,
    title: "",
    disputeDescription: "",
    externalDisputeId: "",
    categoryDescription: "",
    reasonDescription: "",
    whenCreated: "",
    merchantId: null,
    merchantName: "",
    disputeStatus: "",
    assignedTo: "",
    paymentInfo: {
      swiftMessageId: "",
      swiftMessageType: "",
      amount: null,
      ccy: "",
      txId: "",
    },
  };

  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [assignToMerchantModal, setAssignToMerchantModal] = useState(false);
  const [modalFetching, setModalFetching] = useState(false);
  const [fireScroll, setFireScroll] = useState(false);
  const [markDecisionModal, setMarkDecisionModal] = useState(false);
  const [disputeInfo, setDisputeInfo] = useState({ ...defaultDesputeInfo });
  const [redirect, setRedirect] = useState(false);
  const [fetchMessages, setFetchMessages] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [fullFile, setFullFile] = useState(null);
  const [disputeMessages, setDisputeMessages] = useState([]);
  const [disputeNewMessages, setDisputeNewMessages] = useState([]);
  const [selectedDecision, setSelectedDecision] = useState("");
  const [descriptionSolution, setDescriptionSolution] = useState("");
  const [lastMessageId, setLastMessageId] = useState("");
  const [messageText, setMessageText] = useState("");
  const accessToken = useSelector(getAccessToken);

  const [image, setImage] = useState({
    src: null,
    name: "",
    allFile: null
  });

  // const [imageOld, setImageOld] = useState({
  //   src: null,
  //    name: ""
  // });

  useEffect(() => {
    const onScroll = () => {
      const div = document.getElementById('messagesContainer');
      div?.scrollTo({
        top: div.scrollHeight,
        behavior: 'smooth',
      })

      setFireScroll(false)
    }
    fireScroll && onScroll()
  }, [fireScroll])

  useEffect(() => {
    setLastMessageId(disputeMessages[disputeMessages.length - 1]?.messageId || "")
  }, [disputeMessages])


  useEffect(() => {
    const fetchDisputesInfo = () => {
      setIsFetching(true);


      axios.get(`/sap/api/v1/dispute/${disputeId}`).then(res => {
        const { data, status } = res;
        setIsFetching(false);

        if (status === 200 || status === 201) {

          setDisputeInfo(data);
          setFetchMessages(true)
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      })

      // } catch (error) {
      //   console.error("Failed to fetch transaction details:", error);
      // } finally {
      //   setIsFetching(false);
      // }
    };

    fetchDisputesInfo();
  }, [accessToken, disputeId]);

  const fetchDisputeMessages = () => {
    setIsFetchingMessages(true);

    axios.get(`/sap/api/v1/dispute/${disputeId}/messages?messageId=${lastMessageId}`).then(res => {
      const { data, status } = res;
      setIsFetchingMessages(false);
      setShowChat(true);

      if (status === 200 || status === 201) {
        // console.log("data?.messageList", data?.messageList)
        // setDisputeMessages(data?.messageList);

        if (lastMessageId !== "") {
          setDisputeMessages([
            ...disputeMessages,
            // ...disputeNewMessages, 
            ...data?.messageList
          ]);
          setDisputeNewMessages(data?.messageList);
        } else {
          setDisputeMessages(data?.messageList);
        }

        data?.messageList.length > 0 && setLastMessageId(data.messageList[data.messageList.length - 1].messageId)
        setFetchMessages(false)
        setFireScroll(true)
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        // setTimeout(() => {
        //   setRedirect(true);
        // }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        // setTimeout(() => {
        //   setRedirect(true);
        // }, 3000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        // setTimeout(() => {
        //   setRedirect(true);
        // }, 3000);
      }
    })


    // } catch (error) {
    //   console.error("Failed to fetch transaction details:", error);
    // } finally {
    //   setIsFetchingMessages(false);
    // }
    // setTimeout(() => {
    //   setIsFetchingMessages(false);
    // }, 1000);
  };

  useEffect(() => {
    fetchMessages && fetchDisputeMessages()
  }, [fetchMessages]);

  useEffect(() => {

    if (disputeInfo.disputeStatus === "discussion") {
      const intervalId = setInterval(fetchDisputeMessages, 5000);
      return () => clearInterval(intervalId);
    }
  }, [fetchDisputeMessages, disputeInfo]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.code === 'Enter' && messageText.length > 0) {
        event.preventDefault();
        image.name ? onUploadFile() : sendMessage()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [messageText, image])

  const navBarTitle = `${disputeInfo.title}`;

  const breadcrumbs = {
    step1Label: t("Disputs"),
    step1Url: "/app/disputs",
    step2Label: `#${disputeId}`,
  };

  const downloadFile = async (fileId, fileName) => {

    axios.get(`/sap/api/v1/file/${fileId}`, {
      responseType: 'blob' // Важно указать тип ответа как 'blob'
    }).then(res => {
      const { data, status } = res;


      if (status === 200 || status === 201) {

        const imageUrl = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = imageUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);

      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });

      }
    })
  }

  const renderDisputeMessages = () => {

    const content = disputeMessages.map(message => {
      const { isBankMessage, messageText, fileName, fileId, whenCreated } = message
      return (
        <div className={classNames({
          [styles.message_bubble]: true,
          [styles.message_bubble_is_bank]: isBankMessage,
        })}
        >

          {!isBankMessage && <CornerBankIcon />}
          <div>
            {messageText}
            <div className={styles.message_data}>
              {moment(whenCreated).format("DD/MM/YYYY HH:mm")}
            </div>
            {fileName && <div className={styles.hr} />}
            {fileName &&
              <span className={styles.download_file}>{fileName}
                <b onClick={() => downloadFile(fileId, fileName)}><DownloadIcon /></b>
              </span>
            }
          </div>
          {isBankMessage && <CornerMyIcon />}

        </div>
      )
    })
    return content;
  }

  const renderEmptyMessagesArea = () => {
    return (
      <div className={styles.no_messages}>
        <DisputsIcon />
        {t('Star_messages_with_merchant')}
      </div>
    )
  }

  const renderMessages = () => {
    const content = disputeMessages.length > 0 ? renderDisputeMessages() : renderEmptyMessagesArea();

    return content;
  }


  const onUploadFile = async () => {
    setIsSending(true)
    const formData = new FormData();
    formData.append('file', fullFile);


    // setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "post",
        `/sap/api/v1/file`,
        formData
      );

      if (status === 200 || status === 201) {
        const {
          data
        } = response;
        sendMessage(data);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch merchants:", error);
    } finally {
      setIsSending(false);
    }



    // axios.post(`/sap/api/v1/file`, formData)
    //   .then((result) => {
    //     const { data, status } = result;
    //     console.log("++++++++++++++++++++ ", result.status)
    //     if (status === 200 || status === 201) {

    //       sendMessage(data);


    //     } else if (status === 400) {
    //       enqueueSnackbar(t("Error_400"), { variant: "error" });
    //     } else if (status === 401) {
    //       enqueueSnackbar(t("Error_401"), { variant: "error" });
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 3000);
    //     } else if (status === 404) {
    //       enqueueSnackbar(t("Error_404"), { variant: "error" });
    //       setTimeout(() => {
    //         setRedirect(true);
    //       }, 3000);
    //     } else if (status === 500) {
    //       enqueueSnackbar(t("Error_500"), { variant: "error" });
    //       setTimeout(() => {
    //         setRedirect(true);
    //       }, 3000);
    //     } else if ([501, 502, 503, 504, 505].includes(status)) {
    //       enqueueSnackbar(t("Error_500"), { variant: "error" });
    //       setTimeout(() => {
    //         setRedirect(true);
    //       }, 3000);
    //     }
    //   }).catch(() => {
    //     enqueueSnackbar(t("Error_500"), { variant: "error" });
    //   })

  }

  const sendMessage = (guid = "") => {
    setIsSending(true)
    const params = {
      text: messageText,
      fileId: guid
    }
    axios.post(`/sap/api/v1/dispute/${disputeId}/message`, { ...params })
      .then((result) => {
        const { data, status } = result;
        setFetchMessages(true)
        setMessageText("");
        setImage({
          src: null,
          name: "",
          allFile: null
        });
        setIsSending(false)
    
        if (status === 200 || status === 201) {
          if (data.result === "sent") {
            fetchDisputeMessages()
            enqueueSnackbar(t("Sent"), { variant: "success" });
          } else if (data.result === "dispute_not_found") {
            enqueueSnackbar(t("Dispute_not_found"), { variant: "error" });
          } else if (data.result === "wrong_dispute_status") {
            enqueueSnackbar(t("Wrong_dispute_status"), { variant: "error" });
          } else if (data.result === "file_not_found") {
            enqueueSnackbar(t("File_not_found"), { variant: "error" });
          }

        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }

        const element = document.getElementById('messagesContainer')
        const bodyRect = document.body.getBoundingClientRect()
        const elemRect = element.getBoundingClientRect()
        const needScrollToMyDivInPX = elemRect.top - bodyRect.top

        window.scrollTo({
          top: needScrollToMyDivInPX,
          behavior: 'smooth',
        })

      })
  }

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    const getBase64 = (file, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {

        cb(reader.result);
      };

      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

    getBase64(file, (result) => {

      setImage({
        name: file.name,
        src: result,
        allFile: file
      });
    });
  };

  const onFileUpload = (event) => {
    const file = event.target.files[0];


    let isValid = isImageValid(file, t, enqueueSnackbar);
    if (isValid) {
      setFullFile(file)
      event && handleFileUpload(event);
    }

  };

  const renderInputContainer = () => {
    return (
      <div className={styles.input_container}>
        <div className={styles.input_entered_messages}>

          {!image.src && <Button
            component="label"
            className={classNames({
              [styles.attach_logo]: true,
              [styles.disable_attach]: disputeInfo.disputeStatus !== "discussion",
            })}
            disabled={disputeInfo.disputeStatus !== "discussion"}
          >
            <AttachFileClipIcon />
            <input
              type="file"
              hidden
              onChange={(e) => onFileUpload(e)}
            />
          </Button>}

          <input
            type="text"
            placeholder={t("Start_typing")}
            onChange={(e) => setMessageText(e.target.value)}
            autoFocus
            className={classNames({
              [styles.disabled_send_message]: disputeInfo.disputeStatus === "closed",
              [styles.colors]: messageText.length === 0,
            })}
            value={messageText}
            disabled={disputeInfo.disputeStatus !== "discussion" || isSending}
          />
          {isSending ? <CircularProgress size={18} /> :
            <SendIcon onClick={() => messageText.length === 0 || isSending ? {} : image.name ? onUploadFile() : sendMessage()}
              className={classNames({
                [styles.disabled_send_message]: messageText.length === 0 || disputeInfo.disputeStatus === "closed" || isSending,
              })}
            />}
        </div>


        {image.src && <div
          className={classNames({
            [`${styles.settings_block_description}`]: true,
            [`${styles.additional_padding}`]: true,
            [`${styles.logo_background}`]: true,
          })}
        >
          <div>
            {image.name}
          </div>

          {image.src && !isSending &&(
            <div className="image-info">

              <CloseIcon
                onClick={() => {
                  setImage({ src: "", name: "", allFile: null })
                }}
                sx={{ color: "#606060", cursor: "pointer" }}
              />
            </div>
          )}
        </div>}

      </div>
    )
  }

  const onAssignToMerchant = () => {
    setModalFetching(true)

    axios.post(`/sap/api/v1/dispute/${disputeId}/assign/merchant`)
      .then((res) => {
        setModalFetching(false)
        const { data, status } = res;
        // - assigned
        // - dispute_not_found
        // - wrong_dispute_status

        if (status === 200 || status === 201) {
          if (data.result === "assigned") {
            enqueueSnackbar(t("Dispute_assigned"), { variant: "success" });
            setAssignToMerchantModal(false);
            setTimeout(() => {
              window.location.reload()
            }, 500);
          } else if (data.result === "dispute_not_found") {
            enqueueSnackbar(t("Dispute_not_found"), { variant: "error" });
          } else if (data.result === "wrong_dispute_status") {
            enqueueSnackbar(t("Wrong_dispute_status"), { variant: "error" });
          }

        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }




      })
  }



  const onCloseDispute = () => {
    setModalFetching(true)

    const params = {
      disputeStatus: selectedDecision,
      finalizeDescription: descriptionSolution
    }
    axios.post(`/sap/api/v1/dispute/${disputeId}/finalize`, { ...params })
      .then((res) => {
        setModalFetching(false);
        setMarkDecisionModal(false)
        const { data, status } = res;
        if (status === 200 || status === 201) {
          if (data.result === "finalized") {
            enqueueSnackbar(t("Dispute_finalized"), { variant: "success" });
            setTimeout(() => {
              setRedirect(true)
            }, 500);
          } else if (data.result === "dispute_not_found") {
            enqueueSnackbar(t("Dispute_not_found"), { variant: "error" });
          } else if (data.result === "wrong_dispute_status") {
            enqueueSnackbar(t("Wrong_dispute_status"), { variant: "error" });
          }

        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }




      })
  }


  const renderAssignMerchantModal = () => {
    return (
      <Dialog
        open={assignToMerchantModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAssignToMerchantModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Confirm_merchant_assigning")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Are_you_sure_to_assign_merchant")}
              <div>"{disputeInfo.merchantName}" ?</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => onAssignToMerchant()}
              variant="contained"
              disabled={false}
              className={classNames({
                [`${styles.cancel_button}`]: true,
                [`${styles.is_disabled}`]: modalFetching,
              })}
            >
              {t("Assign")}
              {modalFetching && (
                <CircularProgress className="circular-progress" size={18} />
              )}
            </Button>
            <Button
              onClick={() => setAssignToMerchantModal(false)}
              variant="text"
              className={styles.exit_button}
            >
              {t("Cancel")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  };


  const closeMarkDecisionModal = () => {
    setMarkDecisionModal(false);
    setSelectedDecision("");
    setDescriptionSolution("");
  }

  const renderMarkDecisionModal = () => {
    return (
      <Dialog
        open={markDecisionModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setMarkDecisionModal(false)}
        aria-describedby="Mark_a_decision"
      >
        <div
          className={classNames({
            [styles.custom_modal_container]: true,
            [styles.modal_content_decision]: true,
          })}
        >
          <DialogTitle >
            {t("Mark_a_decision")}
          </DialogTitle>
          <DialogContent
            className={classNames({
              [styles.modal_content]: true,
              [styles.modal_content_decision]: true,
            })}
          >
            <DialogContentText
              id="Mark_a_decision"
              className={styles.logout_header}
            >

              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >


                <label htmlFor="selectedDecision">{t("Decision_type")}</label>
                {/* <FormControl>
                  <InputLabel id="demo-simple-select-label">{t("Select")}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  // value={age}
                  // onChange={handleChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl> */}

                <FormControl>
                  <InputLabel id="selectedDecision">{t("Select")}</InputLabel>
                  <Select
                    className="select-lang-selector"
                    labelId="selectedDecision-label"
                    id="selectedDecision"
                    value={selectedDecision}
                    onChange={(e) => setSelectedDecision(e.target.value)}
                  >
                    <MenuItem value={"approved"} key={1}>{t("Approved_decision")} </MenuItem>
                    <MenuItem value={"canceled"} key={1}>{t("Canceled_decision")} </MenuItem>
                    <MenuItem value={"closed"} key={1}>{t("Closed_decision")} </MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="selectedDecision">{t("Description_solution")}</label>
                <FormControl>
                  <TextField
                    id="outlined-basic"
                    label={t('We_decided_to')}
                    multiline
                    variant="outlined"
                    value={descriptionSolution}
                    onChange={(e) => setDescriptionSolution(e.target.value)}
                  />
                </FormControl>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => onCloseDispute()}
              variant="contained"
              disabled={!selectedDecision || !descriptionSolution || modalFetching}
              className={classNames({
                [`${styles.cancel_button}`]: true,
                [`${styles.is_disabled}`]: !selectedDecision || !descriptionSolution || modalFetching,
              })}
            >
              {t("Apply")}
              {modalFetching && (
                <CircularProgress className="circular-progress" size={18} />
              )}
            </Button>
            <Button
              onClick={() => closeMarkDecisionModal()}
              variant="text"
              className={styles.exit_button}
            >
              {t("Cancel")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }}>
      <div>
        {redirect && <Redirect to={"/app/disputs/"} />}
        {renderAssignMerchantModal()}
        {renderMarkDecisionModal()}
        <div
          className={classNames({
            // [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <div className={styles.forms_wrapper}>
              <div className={styles.info_block}>
                <div className={styles.info_block_title}>
                  <span>{t("General")}</span>
                  {disputeInfo.whenCreated && (
                    <span className={styles.creation_date}>
                      {t("Creation_date")}:{" "}
                      {moment(disputeInfo.whenCreated).format("DD/MM/YYYY")}
                    </span>
                  )}
                </div>
                <div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Disp_Id")}</label>
                    <div className={styles.block_value}>
                      {disputeInfo.disputeId}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Disp_title")}</label>
                    <div className={styles.block_value}>
                      {disputeInfo.title}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Status")}</label>
                    <div className={styles.block_value}>
                      {UseErrorLabel(disputeInfo.disputeStatus, t)}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Disp_assigned_on")}</label>
                    <div className={styles.block_value}>
                      {UseErrorLabel(disputeInfo.assignedTo, t)}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("MIA_disp_Id_title")}</label>
                    <div className={styles.block_value}>
                      {disputeInfo.externalDisputeId ? disputeInfo.externalDisputeId : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Merchant")}</label>
                    <div className={styles.block_value}>
                      <Link
                        to={`/app/merchants/${disputeInfo.merchantId}`}
                        className={styles.no_underline}
                      >
                        {disputeInfo.merchantName} ({disputeInfo.merchantId})
                      </Link>
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Category")}</label>
                    <div className={styles.block_value}>
                      {disputeInfo.categoryDescription
                        ? disputeInfo.categoryDescription
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Reason")}</label>
                    <div className={styles.block_value}>
                      {disputeInfo.reasonDescription
                        ? disputeInfo.reasonDescription
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Description")}</label>
                    <div className={styles.block_value_text}>
                      {disputeInfo.disputeDescription
                        ? disputeInfo.disputeDescription
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Swift_message_ID")}</label>
                    <div className={styles.block_value}>
                      {disputeInfo.paymentInfo.swiftMessageId
                        ? disputeInfo.paymentInfo.swiftMessageId
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Swift_message_type")}</label>
                    <div className={styles.block_value}>
                      {disputeInfo.paymentInfo.swiftMessageType
                        ? disputeInfo.paymentInfo.swiftMessageType
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Amount_MDL")}</label>
                    <div className={styles.block_value}>
                      {disputeInfo.paymentInfo.amount
                        ? disputeInfo.paymentInfo.amount
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("CCY")}</label>
                    <div className={styles.block_value}>
                      {disputeInfo.paymentInfo.ccy
                        ? disputeInfo.paymentInfo.ccy
                        : "-"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("MIA_POS_tx_ID_title")}</label>
                    <div className={styles.block_value}>
                      {disputeInfo.paymentInfo.txId
                        ? disputeInfo.paymentInfo.txId
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames({
                  [styles.info_block]: true,
                  [styles.info_block_messenger]: true,
                })}
              >
                <div className={styles.messenger_info_block_title}>
                  <div className={styles.messenger_title_name_wrapper}>
                    <span className={styles.messenger_title}>{t("Merchant")}</span>
                    <span className={styles.messenger_title_name}>
                      {disputeInfo.merchantName}
                    </span>
                  </div>

                  <div className={styles.messenger_container_actions_buttons}>

                    {disputeInfo.assignedTo === "bank" && disputeInfo.disputeStatus === "discussion" && <Button
                      variant='contained'
                      className={styles.assign_to_merchant}
                      onClick={() => setAssignToMerchantModal(true)}
                    >
                      {t("Assign_to_merchant")}
                    </Button>}

                    {disputeInfo.disputeStatus === "discussion" && <Button
                      variant='contained'
                      className={styles.make_a_decision}
                      onClick={() => setMarkDecisionModal(true)}
                    >
                      {t("Make_a_decision")}
                    </Button>}

                  </div>
                </div>


                <div
                  className={classNames({
                    [styles.messages_content]: true,
                    [styles.messages_content_no_messages]: !showChat,
                  })}
                  id="messagesContainer"
                >
                  {!showChat ? <CircularProgress size={36} /> : renderMessages()}
                </div>
                {renderInputContainer()}
              </div>
            </div>
          )}
        </div>
      </div>
    </AppMainLayout>
  );
};

export default DisputeInfo;
