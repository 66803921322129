/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  getRefreshToken,
  getAccessToken,
} from "../../api/selectors/tokenSelector";
import Pagination from "../../infrastructure/components/Pagination";
// import Button from "../../infrastructure/components/Button";
import Button from "@mui/material/Button";
import { PlusIcon } from "../../svg-icons/icon-plus";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { setPageSize } from "../../api/actionCreators/mainActionCreator";
import { getMerchantPageSize } from "../../api/selectors/mainSelector";
import { getCurrentLanguage } from "../../api/selectors/languageSelector";
import { pages, FORMAT } from "../../constants";
import useDebounce from "../../hooks/useDebounce";
import { enqueueSnackbar } from "notistack";
// import { Button } from "finergy-kit";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en";
import "dayjs/locale/ro";
import "dayjs/locale/ru";
import dayjs from "dayjs";
import Switch from "@mui/material/Switch";
import { PercentIcon } from "../../svg-icons/icon-percent";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import NotificationItem from "../../infrastructure/components/NotificationItem";
import { apiRequest } from "../../api/services/Api";

import styles from "./commissions.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Commissions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const commissionDefault = {
    merchantId: null,
    startDate: null,
    commissionRate: "",
    minCommission: "",
    startsImmediately: false,
  };

  const [isFetching, setIsFetching] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [showAddNewCommissionModal, setShowAddNewCommissionModal] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [merchatsList, setMerchatsList] = useState([]);
  const [commissionData, setCommissionData] = useState({
    ...commissionDefault,
  });
  const [invalidFields, setInvalidFields] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);

  const language = useSelector(getCurrentLanguage);
  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);
  const pageSize = useSelector(getMerchantPageSize);

  useEffect(() => {
    //if (showAddNewCommissionModal) {
      const getMerchatsList = async () => {
        try {
          const { status, response } = await apiRequest(
            "get",
            `/sap/api/v1/merchant/brief`,
            null
          );

          if (status === 200 || status === 201) {
            const { data } = response;
            setMerchatsList(data.merchantList);
          } else if (status === 400) {
            enqueueSnackbar(t("Error_400"), { variant: "error" });
          } else if (status === 401) {
            enqueueSnackbar(t("Error_401"), { variant: "error" });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else if (status === 404) {
            enqueueSnackbar(t("Error_404"), { variant: "error" });
            setTimeout(() => {
              setShowAddNewCommissionModal(false);
            }, 3000);
          } else if (status === 500) {
            enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
            setTimeout(() => {
              setShowAddNewCommissionModal(false);
            }, 3000);
          } else if ([501, 502, 503, 504, 505].includes(status)) {
            enqueueSnackbar(t("Error_500"), { variant: "error" });
            setTimeout(() => {
              setShowAddNewCommissionModal(false);
            }, 3000);
          }
        } catch (error) {
          console.error("Failed to fetch merchants list:", error);
        }
      };
      getMerchatsList();
    // }
  }, []);

  const fetchCommissions = useCallback(async (searchText,
    currentPage,
    pageSize,
    selectedMerchant) => {
    setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/commission?offset=${currentPage * pageSize
        }&count=${pageSize}&filter=${searchText}&merchantId=${selectedMerchant?.id || ""
        }`,
        null
      );

      if (status === 200 || status === 201) {
        const {
          data: {
            commissionList,
            pagination: { totalCount },
          },
        } = response;
        setRows(commissionList || []);
        setTotalCount(totalCount);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch merchants:", error);
    } finally {
      setIsFetching(false);
    }
  }, [searchText, currentPage, pageSize]);

  useDebounce(
    () => {
      fetchCommissions(searchText,
        currentPage,
        pageSize,
        selectedMerchant);
    },
    [searchText, currentPage, pageSize, fetchCommissions, token, accessToken, selectedMerchant],
    800
  );
  console.log("commissionData+++++++++", commissionData)
  const onAddNewCommission = () => {
    setIsAdding(true);
   
    let requestObj = {};

    if (commissionData.startsImmediately) {
      const params = {
        merchantId: commissionData.merchantId.id,
        // startDate: commissionData.startDate,
        commissionRate: Number(commissionData.commissionRate),
        minCommission: Number(commissionData.minCommission),
        startsImmediately: commissionData.startsImmediately,
      };

      requestObj = { ...params };
    } else {
      const params = {
        merchantId: commissionData.merchantId.id,
        startDate: dayjs(commissionData.startDate).format(FORMAT),
        commissionRate: Number(commissionData.commissionRate),
        minCommission: Number(commissionData.minCommission),
        // startsImmediately: commissionData.startsImmediately
      };

      requestObj = { ...params };
    }

    const add = async (requestObj) => {
      setIsFetching(true);
      try {
        const { status } = await apiRequest(
          "post",
          `/sap/api/v1/commission`,
          { ...requestObj }
        );
        setCommissionData({ ...commissionDefault });
        setIsAdding(false);
        setShowAddNewCommissionModal(false);

        if (status === 200 || status === 201) {
          enqueueSnackbar(
            <NotificationItem
              id={commissionData.merchantId.id}
              text={commissionData.merchantId.merchantName}
              locale={"Just_added_commision"}
              url="/app/merchants/"
              localeOther=""
            />,
            { variant: "success" }
          );
          setTimeout(() => {
            fetchCommissions(searchText,
              currentPage,
              pageSize,
              selectedMerchant);
          }, 1000);
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
        } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        }
      } catch (error) {
        console.error("Failed to fetch merchants:", error);
      } finally {
        setIsFetching(false);
      }
    };
    add(requestObj);
  };

  // else if (state === "partialSumm") {
  //   if ((value !== "-" || value === "") && value[value.length - 1] !== "-") {
  //     data = {
  //       ...revertSummData,
  //       partialSumm: "",
  //       [state]: value,
  //     };
  //     setRevertSummData(data);
  //   }
  // } 

  const onChange = (state, value) => {

    const decimal = /^\d*\.?\d*$/

    if (state === "startsImmediately") {
      const data = {
        ...commissionData,
        [state]: value,
        startDate: value ? dayjs().format(FORMAT) : null,
      };

      setCommissionData(data);
    } else if (state === "minCommission" || state === "commissionRate") {
      if (decimal.test(value)) {
        const data = {
          ...commissionData,
          [state]: value,
        };
        setCommissionData(data);
      }
    } else {
      const data = {
        ...commissionData,
        [state]: value,
      };

      setCommissionData(data);
    }
  };

  const removeError = (stateName) => {
    if (invalidFields && invalidFields.length > 0) {
      const fIndex =
        invalidFields && invalidFields.findIndex((e) => e === stateName);
      const errorListFields = [
        ...invalidFields?.slice(0, fIndex),
        ...invalidFields?.slice(fIndex + 1),
      ];

      setInvalidFields(errorListFields || []);
    }
  };

  const disabledButton =
    !commissionData.merchantId || !commissionData.commissionRate || !commissionData.minCommission;

  const renderAddnewCommisionModal = () => {
    return (
      <Dialog
        open={showAddNewCommissionModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowAddNewCommissionModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("New_commision")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="New_commision"
              className={styles.logout_header}
            >
              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="merchantId">{t("Merchant_id")}</label>
                <FormControl fullWidth>
                  <Autocomplete
                    id="merchantId"
                    options={merchatsList}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option?.id ? `#${option.id} ${option.merchantName}` : ""
                    }
                    value={commissionData.merchantId || null}
                    onChange={(event, newValue) => {
                      onChange("merchantId", newValue || null);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        #{option.id} {option.merchantName}
                      </Box>
                    )}
                    className={styles.autocomplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Merchant_id")}
                        onFocus={() => removeError("merchantId")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "merchantId") >
                          -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "merchantId") >
                          -1
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  {invalidFields?.length > 0 &&
                    invalidFields.findIndex((i) => i === "merchantId") > -1 && (
                      <FormHelperText style={{ color: "#d32f2f" }}>
                        {t("Required_field")}
                      </FormHelperText>
                    )}
                </FormControl>
              </div>

              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="merchantId">{t("Start_date")}</label>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={language ?? "ro"}
                >
                  <DatePicker
                    label={t("Date_to")}
                    value={
                      commissionData.startDate
                        ? dayjs(commissionData.startDate)
                        : null
                    }
                    onChange={(newValue) => {
                      onChange("startDate", newValue || "");
                    }}
                    minDate={dayjs().add(1, 'day')}
                    format={commissionData.startDate && "DD/MM/YYYY"}
                    disablePast
                    disabled={commissionData.startsImmediately}
                  />
                </LocalizationProvider>
              </div>

              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="merchantId">{t("Apply_new_commission")}</label>

                <Switch
                  onChange={(e) =>
                    onChange("startsImmediately", e.target.checked)
                  }
                />
              </div>

              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="merchantId">{t("Commision_rate")}</label>

                <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <OutlinedInput
                    value={commissionData.commissionRate}
                    id="commissionRate"
                    type="text"
                    className={styles.disabled_password_input}
                    // disabled={true}
                    onFocus={() => removeError("commissionRate")}
                    // helperText={

                    // }
                    inputProps={{
                      "aria-label":
                        invalidFields?.length > 0 &&
                        invalidFields.findIndex((i) => i === "commissionRate") >
                        -1 &&
                        t("Required_field"),
                      "min": 0
                    }}
                    error={
                      invalidFields?.length > 0 &&
                      invalidFields.findIndex((i) => i === "commissionRate") >
                      -1
                    }
                    onChange={(e) => onChange("commissionRate", e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <PercentIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  {invalidFields?.length > 0 &&
                    invalidFields.findIndex((i) => i === "commissionRate") >
                    -1 && (
                      <div className={styles.error_container}>
                        {t("Required_field")}
                      </div>
                    )}
                </FormControl>
              </div>



              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="minCommission">{t("Min_commision_rate")}</label>

                <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <OutlinedInput
                    value={commissionData.minCommission}
                    id="minCommission"
                    type="text"
                    className={styles.disabled_password_input}
                    // disabled={true}
                    onFocus={() => removeError("minCommission")}
                    // helperText={

                    // }
                    inputProps={{
                      "aria-label":
                        invalidFields?.length > 0 &&
                        invalidFields.findIndex((i) => i === "minCommission") >
                        -1 &&
                        t("Required_field"),
                      "min": 0
                    }}
                    error={
                      invalidFields?.length > 0 &&
                      invalidFields.findIndex((i) => i === "minCommission") >
                      -1
                    }
                    onChange={(e) => onChange("minCommission", e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          sx={{
                            fontSize: 14,
                            fontWeight: 700,
                            color: "#5b636d"
                          }}
                        >
                          MDL
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {invalidFields?.length > 0 &&
                    invalidFields.findIndex((i) => i === "minCommission") >
                    -1 && (
                      <div className={styles.error_container}>
                        {t("Required_field")}
                      </div>
                    )}
                </FormControl>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() =>
                isAdding || disabledButton ? {} : onAddNewCommission()
              }
              variant="contained"
              disabled={false}
              className={classNames({
                [`${styles.cancel_button}`]: true,
                [`${styles.is_disabled}`]: isAdding || disabledButton,
              })}
            >
              {t("Apply")}
              {isAdding && (
                <CircularProgress className="circular-progress" size={18} />
              )}
            </Button>
            <Button
              onClick={() => closeModal()}
              variant="text"
              className={styles.exit_button}
            >
              {t("Cancel")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const closeModal = () => {
    setCommissionData({ ...commissionDefault });
    setShowAddNewCommissionModal(false);
  };

  const renderLicensesCells = () => {
    return [
      {
        field: "commissionId",
        headerName: t("Id_settings"),
        flex: 0.6,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return row.commissionId;
        },
      },
      {
        field: "merchantName",
        headerName: t("Name"),
        flex: 1.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/merchants/${row.merchantId}`}
              className={styles.no_underline}
            >
              {row.merchantName}
            </Link>
          );
        },
      },
      {
        field: "merchantIdno",
        headerName: t("IDNO"),
        flex: 0.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/merchants/${row.merchantId}`}
              className={styles.no_underline}
            >
              {row.merchantIdno}
            </Link>
          );
        },
      },
      {
        field: "whenCreated",
        headerName: t("Registration_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => {
          return moment(row.whenCreated).format("DD/MM/YYYY HH:mm");
        },
      },
      {
        field: "commissionRate",
        headerName: t("Commision_rate"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return `${row.commissionRate}%`;
        },
      },
      {
        field: "minCommission",
        headerName: t("Min_commision_rate"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return row.minCommission;
        },
      },
      {
        field: "startDate",
        headerName: t("Start_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return moment(row.startDate).format("DD/MM/YYYY HH:mm");
        },
      },
      {
        field: "endDate",
        headerName: t("End_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return row.endDate !== null
            ? moment(row.endDate).format("DD/MM/YYYY HH:mm")
            : "-";
        },
      },
      {
        field: "createdBy",
        headerName: t("Admin"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_name,
        renderCell: ({ row }) => {
          return row.createdBy;
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setIsFetching(true);
    setCurrentPage(0);
    dispatch(setPageSize(pages.MERCHANT_PAGE, size));
  };

  return (
    <AppMainLayout navBarTitle={t("Commissions")}>
      {/* {(redirectId === 0 || redirectId > 0) && (
        <Redirect to={`/app/merchants/${redirectId}`} />
      )} */}
      {renderAddnewCommisionModal()}



      <div className={styles.customer_page_bottom_container}>
        <FormControl fullWidth >
          <InputLabel htmlFor="outlined-adornment-amount">
            {t("Search")}
          </InputLabel>
          <OutlinedInput
            id="Commissions"
            startAdornment={<SearchIcon />}
            label={t("Search")}
            value={searchText}
            onChange={(e) => {
              setCurrentPage(0);
              setSearchText(e.target.value);
            }}
          />
        </FormControl>

        <div
          className={classNames({
            [styles.info_block_input_container_search]: true,
          })}
        >
          <FormControl fullWidth>
            <Autocomplete
              id="country-select-demo"
              options={merchatsList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && `#${option.id} ${option.merchantName}`
              }
              value={selectedMerchant || { id: "" }}
              onChange={(event, newValue) => {
                setTimeout(() => {
                  setCurrentPage(0);
                }, 700);
                setSelectedMerchant(newValue);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  #{option.id} {option.merchantName}
                </Box>
              )}
              className={styles.autocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Merchant_id")}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </FormControl>
        </div>

        <Button
          variant="contained"
          // icon={<PlusIcon />}
          className={classNames({
            [styles.add_new_item]: true,
          })}
          onClick={() => setShowAddNewCommissionModal(true)}
        >
          <PlusIcon />
          <div>{t("New_commision")}</div>
        </Button>

      </div>

      <DataTableComponent
        // onCellClick={(row) => onCellClick(row)}
        checkboxSelection={false}
        columns={renderLicensesCells()}
        isFetching={isFetching}
        idChangeValue={"commissionId"}
        {...{ rows }}
      />
      {!isFetching && (
        <Pagination
          onClick={paginationClick}
          onChangePageSize={pageSizeClick}
          total={totalCount}
          pageIndex={currentPage}
          pageSize={pageSize}
        />
      )}

    </AppMainLayout>
  );
};

export default Commissions;
