/* eslint-disable react-hooks/exhaustive-deps */
import cn from "classnames";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Redirect, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAccessToken } from "../../api/selectors/tokenSelector";
import { enqueueSnackbar } from "notistack";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import Button from "../../infrastructure/components/Button";
import { VALIDATION } from "../../constants";
import classNames from "classnames";
import { ResetIcon } from "../../svg-icons/icon-reset";
import { checkPhone } from "../../hooks/checkPhone";
import { apiRequest } from "../../api/services/Api";
import FormControl from "@mui/material/FormControl";
import { PercentIcon } from "../../svg-icons/icon-percent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { DoneIcon } from "../../svg-icons/icon-done";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import styles from "./disput-details.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DisputDetails = () => {
  const { disputId } = useParams();
  const { t } = useTranslation();

  const defaultDisputeData = {
    title: "",
    externalDisputeId: "",
    categoryId: null,
    reasonId: null,
    disputeDescription: "",
    whenCreated: null,
    disputId: null,
    paymentInfo: {
      swiftMessageId: "",
      swiftMessageType: "",
      amount: null,
      ccy: "",
      txId: "",
    },
    merchantId: { id: "" },
  };

  const editMode = disputId !== "new";

  const [isFetching, setIsFetching] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isDisputRedirect, setIsDisputRedirect] = useState(false);
  const [disputeIdCreated, setDisputeIdCreated] = useState("");
  const [fetchEditCapUser, setFetchEditCapUser] = useState(false);
  const [hardBlock, setHardBlock] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [categoriesList, setCategoriesList] = useState([]);
  const [reasonsList, setReasonsList] = useState([]);
  const [merchatsList, setMerchatsList] = useState([]);

  const [disputInfo, setDisputInfo] = useState({ ...defaultDisputeData });
  const [invalidFields, setInvalidFields] = useState([]);

  const [isPaymentInfoChanged, setIsPaymentInfoChanged] = useState(false);
  const [clearPaymentInfo, setClearPaymentInfo] = useState(false);

  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    getMerchatsList();
    getCategoriesList();
    getReasonsList();
  }, [accessToken, editMode]);

  const getCategoriesList = async () => {
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/dispute/categories`,
        null
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        setCategoriesList(data);
        // !editMode && setRunFetchLimits(true)
        // editMode && getPosInfo(mccCodesIn, data.merchantList);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to fetch dispute list:", error);
    }
  };

  const getReasonsList = async () => {
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/dispute/reasons`,
        null
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        setReasonsList(data);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to fetch dispute list:", error);
    }
  };

  const getMerchatsList = async () => {
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/merchant/brief`,
        null
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        setMerchatsList(data.merchantList);
        // !editMode && setRunFetchLimits(true)
        // editMode && getPosInfo(mccCodesIn, data.merchantList);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to fetch dispute list:", error);
    }
  };

  useEffect(() => {
    const fetch = disputId === "new" ? false : true;
    setIsFetching(fetch);
  }, [disputId]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (value) {
      removeError(id);
    }

    if (["swiftMessageId", "swiftMessageType", "amount"].includes(id)) {
      setIsPaymentInfoChanged(true);
      setDisputInfo((prevInfo) => ({
        ...prevInfo,
        paymentInfo: {
          ...prevInfo.paymentInfo,
          [id]: id === "amount" ? parseFloat(value) : value,
        },
      }));
    } else {
      setDisputInfo((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }));
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const regex = /^\d{0,8}(\.\d{0,2})?$/;

    if (regex.test(value)) {
      onChange("amount", value, "paymentInfo");
    }
  };

  const onChange = (state, value, category = "") => {
    const re = /^[0-9]+$/;

    if (
      state === "merchantIdno" ||
      state === "creditorAccount" ||
      state === "idnp"
    ) {
      if (value === "" || re.test(value)) {
        const data = {
          ...disputInfo,
          [category]: {
            ...disputInfo[category],
            [state]: value,
          },
        };
        setDisputInfo(data);
      }
    } else {
      const data = {
        ...disputInfo,
        [category]: {
          ...disputInfo[category],
          [state]: value,
        },
      };
      setDisputInfo(data);
    }

    if (["swiftMessageId", "swiftMessageType", "amount"].includes(state)) {
      setIsPaymentInfoChanged(true);
    }
  };

  const validateInput = () => {
    const result = [];

    VALIDATION.ADD_DISPUT_FORM.forEach((field) => {
      if (
        !isExpanded &&
        ["swiftMessageId", "swiftMessageType", "amount"].includes(field)
      ) {
        return;
      }
      if (field === "merchantId") {
        if (!disputInfo.merchantId || !disputInfo.merchantId.id) {
          result.push(field);
        }
      } else if (
        (field in disputInfo && !disputInfo[field]) ||
        (field in disputInfo.paymentInfo && !disputInfo.paymentInfo[field])
      ) {
        result.push(field);
      }
    });

    return result.length > 0 ? result : null;
  };

  const createDisput = async () => {
    setIsFetching(true);

    const shouldIncludePaymentInfo =
      isExpanded &&
      (disputInfo.paymentInfo.swiftMessageId ||
        disputInfo.paymentInfo.swiftMessageType ||
        disputInfo.paymentInfo.amount ||
        disputInfo.paymentInfo.ccy ||
        disputInfo.paymentInfo.txId);

    const params = {
      title: disputInfo.title,
      externalDisputeId: disputInfo.externalDisputeId,
      categoryId: disputInfo.categoryId,
      reasonId: disputInfo.reasonId,
      disputeDescription: disputInfo.disputeDescription,
      merchantId: disputInfo.merchantId.id,
      ...(shouldIncludePaymentInfo && {
        paymentInfo: {
          swiftMessageId: disputInfo.paymentInfo.swiftMessageId,
          swiftMessageType: disputInfo.paymentInfo.swiftMessageType,
          amount:
            disputInfo.paymentInfo.amount !== null
              ? Number(disputInfo.paymentInfo.amount)
              : 0,
          ccy: disputInfo.paymentInfo.ccy,
          txId: disputInfo.paymentInfo.txId
          ? parseInt(disputInfo.paymentInfo.txId)
          : null,
        },
      }),
    };

    const invalidFields = validateInput();
    setInvalidFields(invalidFields);
    if (invalidFields) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setIsFetching(false);
      return;
    }

    try {
      if (!invalidFields) {
        const { status, response } = await apiRequest(
          "post",
          `/sap/api/v1/dispute`,
          params
        );

        if (status === 200 || status === 201) {
          if (response.data.result === "success") {
            // setRedirect(true);
            setDisputeIdCreated(response.data.disputeId);
            setIsDisputRedirect(true);
            enqueueSnackbar(t("Dispute_successfully_created"), {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              t("Error_200_start_message") +
                response.data.result +
                t("Error_200_end_message"),
              { variant: "error" }
            );
            setTimeout(() => {
              setRedirect(true);
            }, 1000);
          }
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      }
    } catch (error) {
      console.error("Failed to create disput:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const navBarTitle = disputInfo.disputId || t("New_disput");
  // const disabledButton =
  //   JSON.stringify(disputInfo) === JSON.stringify(oldMerchantInfo);

  const breadcrumbs = {
    step1Label: t("Disputs"),
    step1Url: "/app/disputs",
    step2Label: `${disputInfo.disputId || t("New_disput")}`,
  };

  const removeError = (stateName) => {
    setInvalidFields((prevInvalidFields) =>
      prevInvalidFields.filter((field) => field !== stateName)
    );
  };

  const handleAccordionChange = (event, newExpanded) => {
    if (!newExpanded && isPaymentInfoChanged) {
      setClearPaymentInfo(true);
    } else {
      setIsExpanded(newExpanded);
    }
  };

  const renderClearPaymentInfoModal = () => {
    return (
      <Dialog
        open={clearPaymentInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setClearPaymentInfo(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Confirm_payment_information_delete")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Confirm_payment_information_delete_message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              variant="primary"
              className={styles.confirm_btn}
              label={t("Confirm_btn")}
              onClick={() => {
                setDisputInfo((prevInfo) => ({
                  ...prevInfo,
                  paymentInfo: {
                    swiftMessageId: "",
                    swiftMessageType: "",
                    amount: "",
                    ccy: "",
                    txId: "",
                  },
                }));
                setIsPaymentInfoChanged(false);
                setClearPaymentInfo(false);
                setIsExpanded(false);
              }}
            />
            <Button
              variant="ghost"
              className={styles.exit_button}
              label={t("Cancel")}
              onClick={() => setClearPaymentInfo(false)}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }} noBackground>
      {renderClearPaymentInfoModal()}
      <div>
        {redirect && <Redirect to={"/app/disputs/"} />}
        {isDisputRedirect && (
          <Redirect to={`/app/disputs/${disputeIdCreated}`} />
        )}
        <div
          className={cn({
            [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <>
              <div className={styles.forms_wrapper}>
                <div className={styles.info_block}>
                  <div className={styles.info_block_title}>
                    <span>{t("General")}</span>
                    {disputInfo.whenCreated && (
                      <span className={styles.creation_date}>
                        {t("Creation_date")}:{" "}
                        {moment(disputInfo.whenCreated).format("DD/MM/YYYY")}
                      </span>
                    )}
                  </div>

                  <div className={styles.info_block_content}>
                    {editMode && (
                      <div
                        className={cn({
                          [styles.info_block_input_container]: true,
                        })}
                      >
                        <label>ID</label>
                        <div className={styles.block_value}>#{disputId}</div>
                      </div>
                    )}
                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="title">{t("Disp_title")}</label>
                      <TextField
                        variant="outlined"
                        // label={t("Disp_title")}
                        placeholder={t("Disp_title")}
                        type={"text"}
                        id="title"
                        value={disputInfo.title}
                        onFocus={() => removeError("title")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "title") > -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "title") > -1
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="disputId">{t("MIA_disp_Id")}</label>
                      <TextField
                        variant="outlined"
                        placeholder={t("MIA_disp_Id_title")}
                        type={"text"}
                        id="externalDisputeId"
                        value={disputInfo.externalDisputeId}
                        onFocus={() => removeError("externalDisputeId")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex(
                            (i) => i === "externalDisputeId"
                          ) > -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex(
                            (i) => i === "externalDisputeId"
                          ) > -1
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="category">{t("Category")}</label>

                      <FormControl fullWidth>
                        <Autocomplete
                          id="categoryId"
                          options={categoriesList}
                          autoHighlight
                          getOptionLabel={(option) =>
                            option.categoryDescription
                          }
                          onChange={(event, newValue) => {
                            setDisputInfo((prevInfo) => ({
                              ...prevInfo,
                              categoryId: newValue ? newValue.id : null,
                            }));
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.categoryDescription}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("Category")}
                              onFocus={() => removeError("categoryId")}
                              helperText={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "categoryId"
                                ) > -1 &&
                                t("Required_field")
                              }
                              error={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "categoryId"
                                ) > -1
                              }
                            />
                          )}
                        />
                        {invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "id") > -1 && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              {t("Required_field")}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </div>

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="category">{t("Reason")}</label>

                      <FormControl fullWidth>
                        <Autocomplete
                          id="reasonId"
                          options={reasonsList}
                          autoHighlight
                          getOptionLabel={(option) => option.reasonDescription}
                          onChange={(event, newValue) => {
                            setDisputInfo((prevInfo) => ({
                              ...prevInfo,
                              reasonId: newValue ? newValue.id : null,
                            }));
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.reasonDescription}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("Reason")}
                              onFocus={() => removeError("reasonId")}
                              helperText={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "reasonId"
                                ) > -1 &&
                                t("Required_field")
                              }
                              error={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "reasonId"
                                ) > -1
                              }
                            />
                          )}
                        />

                        {invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "id") > -1 && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              {t("Required_field")}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="Disput_reason">{t("Description")}</label>
                      <TextField
                        variant="outlined"
                        placeholder={t("Description")}
                        type={"text"}
                        multiline
                        rows={3}
                        id="disputeDescription"
                        value={disputInfo.disputeDescription}
                        onFocus={() => removeError("disputeDescription")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex(
                            (i) => i === "disputeDescription"
                          ) > -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex(
                            (i) => i === "disputeDescription"
                          ) > -1
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="disputeDescription">
                        {t("Merchant_single")}
                      </label>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="merchantId"
                          options={merchatsList}
                          autoHighlight
                          getOptionLabel={(option) =>
                            option.merchantName
                              ? `#${option.id} ${option.merchantName}`
                              : ""
                          }
                          onChange={(event, newValue) => {
                            setDisputInfo((prevInfo) => ({
                              ...prevInfo,
                              merchantId: newValue
                                ? { id: newValue.id }
                                : { id: "" },
                            }));
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              #{option.id} {option.merchantName}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("Merchant_single")}
                              onFocus={() => removeError("merchantId")}
                              helperText={
                                invalidFields?.includes("merchantId") && t("")
                              }
                              error={invalidFields?.includes("merchantId")}
                            />
                          )}
                        />
                        {invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "merchantId") >
                            -1 && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              {t("Required_field")}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div>
                  <Accordion
                    expanded={isExpanded}
                    onChange={handleAccordionChange}
                  >
                    <AccordionSummary
                      expandIcon={isExpanded ? <CloseIcon /> : <AddIcon />}
                      aria-controls="payment-info-content"
                      id="payment-info-header"
                      sx={{
                        backgroundColor: "#f4f5f7",
                        minHeight: 41,
                        height: 41,
                        "&.Mui-expanded": {
                          minHeight: 41,
                          height: 41,
                        },
                        "& .MuiAccordionSummary-content": {
                          margin: 0,
                        },
                      }}
                    >
                      <span>{t("Payment_info")}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={styles.info_block_content}>
                        <div className={styles.info_block_input_container}>
                          <label htmlFor="paymentInfo">
                            {t("Swift_message_ID")}
                          </label>
                          <TextField
                            variant="outlined"
                            placeholder={t("Swift_message_ID")}
                            type="text"
                            id="Swift_message_ID"
                            value={disputInfo.paymentInfo.swiftMessageId}
                            onFocus={() => removeError("swiftMessageId")}
                            helperText={
                              Array.isArray(invalidFields) &&
                              invalidFields.includes("swiftMessageId") &&
                              t("Required_field")
                            }
                            error={
                              Array.isArray(invalidFields) &&
                              invalidFields.includes("swiftMessageId")
                            }
                            onChange={(e) =>
                              onChange(
                                "swiftMessageId",
                                e.target.value,
                                "paymentInfo"
                              )
                            }
                          />
                        </div>

                        <div className={styles.info_block_input_container}>
                          <label htmlFor="paymentInfo">
                            {t("Swift_message_type")}
                          </label>
                          <TextField
                            variant="outlined"
                            placeholder={t("Swift_message_type")}
                            type="text"
                            id="Swift_message_type"
                            value={disputInfo.paymentInfo.swiftMessageType}
                            onFocus={() => removeError("swiftMessageType")}
                            helperText={
                              Array.isArray(invalidFields) &&
                              invalidFields.includes("swiftMessageType") &&
                              t("Required_field")
                            }
                            error={
                              Array.isArray(invalidFields) &&
                              invalidFields.includes("swiftMessageType")
                            }
                            onChange={(e) =>
                              onChange(
                                "swiftMessageType",
                                e.target.value,
                                "paymentInfo"
                              )
                            }
                          />
                        </div>

                        <div className={styles.info_block_input_container}>
                          <label htmlFor="paymentInfo">{t("Amount_MDL")}</label>
                          <TextField
                            variant="outlined"
                            placeholder={t("Amount_MDL")}
                            type="number"
                            id="Amount_MDL"
                            value={disputInfo.paymentInfo.amount}
                            onFocus={() => removeError("amount")}
                            helperText={
                              Array.isArray(invalidFields) &&
                              invalidFields.includes("amount") &&
                              t("Required_field")
                            }
                            error={
                              Array.isArray(invalidFields) &&
                              invalidFields.includes("amount")
                            }
                            onChange={handleAmountChange}
                          />
                        </div>

                        <div className={styles.info_block_input_container}>
                          <label htmlFor="paymentInfo">{t("CCY")}</label>
                          <TextField
                            variant="outlined"
                            placeholder={t("CCY")}
                            type={"text"}
                            id="CCY"
                            value={disputInfo.paymentInfo.ccy}
                            onFocus={() => removeError("paymentInfo")}
                            helperText={
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex((i) => i === "ccy") >
                                -1 &&
                              t("Required_field")
                            }
                            error={
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex((i) => i === "ccy") > -1
                            }
                            onChange={(e) =>
                              onChange("ccy", e.target.value, "paymentInfo")
                            }
                          />
                        </div>

                        <div className={styles.info_block_input_container}>
                          <label htmlFor="paymentInfo">
                            {t("MIA_POS_tx_ID")}
                          </label>
                          <TextField
                            variant="outlined"
                            placeholder={t("MIA_POS_tx_ID")}
                            type="number"
                            id="MIA_POS_tx_ID"
                            value={disputInfo.paymentInfo.txId}
                            onFocus={() => removeError("paymentInfo")}
                            helperText={
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex((i) => i === "txId") >
                                -1 &&
                              t("Required_field")
                            }
                            error={
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex((i) => i === "txId") > -1
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                onChange("txId", value, "paymentInfo");
                              }
                            }}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </>
          )}
        </div>

        {!isFetching && (
          <div className={styles.actions_buttons}>
            <div>
              <Button
                icon={<DoneIcon />}
                label={t("Create")}
                variant="primary"
                onClick={() => createDisput()}
                disabled={isFetching || hardBlock}
              />

              <Link to="/app/disputs/" className={styles.no_underline}>
                <Button
                  className={styles.reset_button}
                  variant="ghost"
                  icon={null}
                  // disabled={disabled}
                  label={t("Cancel")}
                />
              </Link>
            </div>
          </div>
        )}
      </div>
    </AppMainLayout>
  );
};

export default DisputDetails;
