/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
// import { Button } from "finergy-kit";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { apiRequest } from "../../api/services/Api";
import {
  getRefreshToken,
  getAccessToken,
} from "../../api/selectors/tokenSelector";
import Pagination from "../../infrastructure/components/Pagination";
import moment from "moment";
import { PlusIcon } from "../../svg-icons/icon-plus";
import Button from "../../infrastructure/components/Button";
import useDebounce from "../../hooks/useDebounce";
import { enqueueSnackbar } from "notistack";
import { setPageSize } from "../../api/actionCreators/mainActionCreator";
import { getTerminalPageSize } from "../../api/selectors/mainSelector";
import { pages } from "../../constants";
import classNames from "classnames";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import styles from "./stores.module.scss";

const Stores = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);
  const [fetchMerchants, setFetchMerchants] = useState(false);
  const [merchatsList, setMerchatsList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);
  const pageSize = useSelector(getTerminalPageSize);

  const getMerchatsList = async () => {
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/merchant/brief`,
        null
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        setMerchatsList(data.merchantList);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch merchants list:", error);
    }
  };

  // useEffect(() => {
  //   fetchStores(searchText, currentPage, pageSize, selectedMerchant)
  // },[])

  const fetchStores = async (
    searchText,
    currentPage,
    pageSize,
    selectedMerchant
  ) => {
    setIsFetching(true);
    try {
      const {
        response,
        response: { status },
      } = await apiRequest(
        "get",
        `/sap/api/v1/store?offset=${
          currentPage * pageSize
        }&count=${pageSize}&filter=${searchText}&merchantId=${
          selectedMerchant?.id || ""
        }`,
        null
      );

      if (status === 200 || status === 201) {
        const {
          data: {
            storeList,
            pagination: { totalCount },
          },
        } = response;

        setRows(storeList || []);
        setTotalCount(totalCount);
        setFetchMerchants(true);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch terminals:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useDebounce(
    () => {
      fetchStores(searchText, currentPage, pageSize, selectedMerchant);
    },
    [searchText, currentPage, pageSize, token, accessToken, selectedMerchant],
    800
  );

  useEffect(() => {
    fetchMerchants && getMerchatsList();
  }, [fetchMerchants]);

  const renderStoresCells = () => {
    return [
      {
        field: "id",
        headerName: t("Store_id"),
        flex: 0.3,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link to={`/app/stores/${row.id}`} className={styles.no_underline}>
              {row.id}
            </Link>
          );
        },
      },
      {
        field: "merchantName",
        headerName: t("Merchant_name"),
        flex: 0.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/merchants/${row.merchantId}`}
              className={styles.no_underline}
            >
              {row.merchantName}
            </Link>
          );
        },
      },
      {
        field: "merchantIdno",
        headerName: t("Merchant_IDNO"),
        flex: 0.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return <span className={styles.no_color}>{row.merchantIdno}</span>;
        },
      },
      {
        field: "storeName",
        headerName: t("Store_name"),
        flex: 0.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link to={`/app/stores/${row.id}`} className={styles.no_underline}>
              {row.storeName}
            </Link>
          );
        },
      },
      {
        field: "storeAddress",
        headerName: t("Address"),
        flex: 0.6,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_address,
        renderCell: ({ row }) => {
          return <span className={styles.no_color}>{row.storeAddress}</span>;
        },
      },
      {
        field: "whenCreated",
        headerName: t("Registration_date"),
        cellClassName: styles.table_cell_date,
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return (
            <span className={styles.no_color}>
              {moment(row.whenCreated).format("DD/MM/YYYY, HH:mm")}
            </span>
          );
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setCurrentPage(0);
    setIsFetching(true);
    dispatch(setPageSize(pages.TERMINAL_PAGE, size));
  };

  return (
    <AppMainLayout navBarTitle={t("Stores")}>
      <div className={styles.customer_page_bottom_container}>
        <FormControl fullWidth sx={{ margin: 0 }}>
          <InputLabel htmlFor="outlined-adornment-amount">
            {t("Search")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<SearchIcon />}
            label={t("Search")}
            value={searchText}
            onChange={(e) => {
              setCurrentPage(0);
              setSearchText(e.target.value);
            }}
          />
        </FormControl>

        <div
          className={classNames({
            [styles.info_block_input_container]: true,
          })}
        >
          <FormControl fullWidth>
            <Autocomplete
              id="country-select-demo"
              options={merchatsList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && `#${option.id} ${option.merchantName}`
              }
              value={selectedMerchant || { id: "" }}
              onChange={(event, newValue) => {
                setTimeout(() => {
                  setCurrentPage(0);
                }, 700);
                setSelectedMerchant(newValue);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  #{option.id} {option.merchantName}
                </Box>
              )}
              className={styles.autocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Merchant_id")}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </FormControl>
        </div>

        <Link to="/app/stores/new" className={styles.no_underline}>
          <Button
            variant="primary"
            icon={<PlusIcon />}
            label={t("Add_new_store")}
            disabled={isFetching}
          />
        </Link>
      </div>

      <DataTableComponent
        checkboxSelection={false}
        columns={renderStoresCells()}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.terminal_table}
        {...{ rows }}
      />

      {!isFetching && (
        <Pagination
          onClick={paginationClick}
          onChangePageSize={pageSizeClick}
          total={totalCount}
          pageIndex={currentPage}
          pageSize={pageSize}
        />
      )}
    </AppMainLayout>
  );
};

export default Stores;
