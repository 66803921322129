/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from "../../infrastructure/components/Button";
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import MerchantDetails from "../MerchantDetails";
import StoreDetails from "../StoreDetails";
import TerminalDetails from "../TerminalDetails";
import {
  resetFlowData
} from "../../api/actionCreators/mainActionCreator";
import SellerDetails from "../SellerDetails";
import MerchantFlowResultScreen from "../MerchantFlowResultScreen";
import { getFlowData } from "../../api/selectors/mainSelector";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import classNames from "classnames";

import styles from "./merchant-flow.module.scss";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MerchantFlow = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inFlowData = useSelector(getFlowData);
  console.log("inFlowData", inFlowData)
  const [checkFormForFlow, setCheckFormForFlow] = useState({ action: false, moveNext: false });

  const steps = [
    t('Merchants'),
    t('Stores'),
    t('Pos_management'),
    t('Sellers'),
    t('Result')
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [onResetForm, setOnResetForm] = useState(false);
  const [skipStepModal, setSkipStepModal] = useState(false);

  useEffect(() => () => {
    dispatch(resetFlowData())
  }, [])

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderSkipStepModal = () => {
    return (
      <Dialog
        open={skipStepModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSkipStepModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Skip_step")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Are_you_sure_to_skip_step")}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              variant="ghost"
              className={classNames({
                [styles.logout_button]: true,
              })}
              label={t("Skip")}
              onClick={() => { handleNext(); setSkipStepModal(false) }}
            />
            <Button
              variant="ghost"
              className={styles.approve_button}
              label={t("Cancel")}
              onClick={() => {
                setSkipStepModal(false);
              }}
            />

          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const renderStepContainer = () => {
    let content

    const commonProps = {
      flowMode: true,
      checkFormForFlow,
      setStepResult,
      onResetForm,
      setOnResetForm,
      setActiveStep
    }

    switch (activeStep) {
      case 0:
        content = <MerchantDetails {...commonProps} />;
        break;
      case 1:
        content = <StoreDetails {...commonProps} />;
        break;
      case 2:
        content = <TerminalDetails {...commonProps} />;
        break;
      case 3:
        content = <SellerDetails {...commonProps} />;
        break;

      default:
        content = <MerchantFlowResultScreen {...commonProps} />;
        break;
    }

    return content;
  }

  const setStepResult = (result) => {
    setCheckFormForFlow({
      action: false,
      moveNext: false
    })

    // const checkDataObj = {
    //   1: "stores",
    //   2: "terminals",
    //   3: "sellers"
    // }

    // const flowStepData = checkDataObj[activeStep];
    // result && handleNext()
    // if(activeStep > 0){
    //   inFlowData[flowStepData].length === 0 ? setSkipStepModal(true) :  result && handleNext()
    // } else {
    result && handleNext()
    // }
    // if(inFlowData[flowStepData].length === 0 && activeStep > 0){
    //   setSkipStepModal(true)
    // } else {
    //   result && handleNext()
    // }

  }

  const checkForm = () => {
    setCheckFormForFlow({
      action: true,
      moveNext: false
    })
  }

  const checkStepForEmptyData = () => {
    const checkDataObj = {
      1: "stores",
      2: "terminals",
      3: "sellers"
    }

    const flowStepData = checkDataObj[activeStep];
    console.log("flowStepData", flowStepData, inFlowData[flowStepData])
    if (activeStep > 0) {
      inFlowData[flowStepData].length === 0 ? setSkipStepModal(true) : handleNext()
    } else {
      handleNext()
    }
  }

  return (
    <AppMainLayout navBarTitle={t("Merchant_flow")}>
      {renderSkipStepModal()}
      <div
        className={styles.flow_mode}
      // onClick={() => setShowReportModalModal(true)}
      >
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps} style={{ cursor: inFlowData.allStepsVisited ? "pointer !important" : "default !important" }} onClick={() => !inFlowData.allStepsVisited ? {} : setActiveStep(index)}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <React.Fragment>
            {<Box className={styles.flow_navigation}>
              {((activeStep !== 0 && activeStep !== 1) || (inFlowData.allStepsVisited && activeStep > 0)) && <Button
                label={t("Back")}
                variant="primary"
                onClick={handleBack}
              />}

              <Box sx={{ flex: '1 1 auto' }} />
              {!inFlowData.allStepsVisited ?
                <div className={styles.next_reset}>
                  {activeStep === 0 && <Button
                    label={t("Reset")}
                    variant="primary"
                    className={styles.next_reset_btn}
                    onClick={() => setOnResetForm(true)}
                  />}
                  {activeStep !== 4 && <Button
                    label={t("Next")}
                    variant="primary"
                    onClick={() => activeStep === 0 ? checkForm() : checkStepForEmptyData()}
                  />}
                </div> :
                <Button
                  label={t("Next")}
                  variant="primary"
                  onClick={() => activeStep === 0 ? handleNext() : checkStepForEmptyData()}
                />
              }



            </Box>}
            <Typography sx={{ mt: 2, mb: 1 }}>
              {renderStepContainer()}
            </Typography>

          </React.Fragment>

        </Box>
      </div>
    </AppMainLayout>
  );
};

export default MerchantFlow;
