/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  getRefreshToken,
  getAccessToken,
} from "../../api/selectors/tokenSelector";
import Pagination from "../../infrastructure/components/Pagination";
import Button from "../../infrastructure/components/Button";
import { PlusIcon } from "../../svg-icons/icon-plus";
import classNames from "classnames";
import styles from "./sellers.module.scss";
import { setPageSize } from "../../api/actionCreators/mainActionCreator";
import { getSellersPageSize } from "../../api/selectors/mainSelector";
import {
  pages,
  filtersSellers,
  capitalize,
} from "../../constants";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import useDebounce from "../../hooks/useDebounce";
import { enqueueSnackbar } from "notistack";
import { apiRequest } from "../../api/services/Api";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';

// import { createServer } from "miragejs";
// import authHandler from "../../api/miragejs/handlers/authHandler";

// const config = require("../../config.json");

// createServer({
//   routes() {
//     authHandler(this, "http://localhost:3000/");
//     this.passthrough();
//   },
// });

const Sellers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [sellerStatus, setSellerStatus] = useState("all");
  const [merchatsList, setMerchatsList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [fetchMerchants, setFetchMerchants] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [rows, setRows] = useState([]);

  const [totalCount, setTotalCount] = useState(0);

  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);
  const pageSize = useSelector(getSellersPageSize);

  const getMerchatsList = async () => {
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/merchant/brief`,
        null
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        setMerchatsList(data.merchantList);

      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });

      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });

      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch merchants list:", error);
    }
  };

  useEffect(() => {
    fetchMerchants && getMerchatsList()
  }, [fetchMerchants])

  const fetchSellers = useCallback(
    async (state = "all", searchText = "", pageSize, currentPage, selectedMerchant) => {
      setIsFetching(true);
      try {
        const f = state === "all" ? " " : state;

        const { status, response } = await apiRequest(
          "get",
          `/sap/api/v1/seller?offset=${currentPage * pageSize
          }&count=${pageSize}&filter=${searchText}&status=${f}&merchantId=${selectedMerchant?.id || ""}`,
          null
        );

        if (status === 200 || status === 201) {
          const {
            data: {
              sellersList,
              pagination: { totalCount },
            },
          } = response;

          setFetchMerchants(true)

          setRows(sellersList || []);
          setTotalCount(totalCount);
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
        } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        }
      } catch (error) {
        console.error("Failed to fetch sellers:", error);
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  useDebounce(
    () => {
      fetchSellers(sellerStatus, searchText, pageSize, currentPage, selectedMerchant);
    },
    [searchText, currentPage, pageSize, token, accessToken, sellerStatus, selectedMerchant],
    500
  );

  const renderLicensesCells = () => {
    return [
      {
        field: "id",
        headerName: t("Seller_id"),
        flex: 0.7,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link to={`/app/sellers/${row.id}`} className={styles.no_underline}>
              {row.id}
            </Link>
          );
        },
      },
      {
        field: "username",
        headerName: t("Login_seller"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        // renderCell: ({ row }) => {
        //   return (
        //     <Link
        //       to={`/app/sellers/${row.id}`}
        //       className={styles.no_underline}
        //     >
        //       {row.username}
        //     </Link>
        //   );
        // },
      },
      {
        field: "merchantName",
        headerName: t("Merchant_name"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/merchants/${row.merchantId}`}
              className={styles.no_underline}
            >
              {row.merchantName}
            </Link>
          );
        },
      },
      {
        field: "fullName",
        headerName: t("Seller_username"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        // renderCell: ({ row }) => {
        //   return (
        //     <Link
        //       to={`/app/sellers/${row.id}`}
        //       className={styles.no_underline}
        //     >
        //       {row.fullName}
        //     </Link>
        //   );
        // },
      },
      {
        field: "storeName",
        headerName: t("Store_name"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/stores/${row.storeId}`}
              className={styles.no_underline}
            >
              {row.storeName}
            </Link>
          );
        },
      },
      {
        field: "status",
        headerName: t("Status"),
        flex: 0.5,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return UseErrorLabel(row.status, t);
        },
      },
      {
        field: "registrationDate",
        headerName: t("Registration_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return moment(row.registrationDate).format("DD/MM/YYYY");
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setIsFetching(true);
    setCurrentPage(0);
    dispatch(setPageSize(pages.SELLERS_PAGE, size));
  };

  const renderFilterItems = () => {
    const content = filtersSellers.map((sellerFilter) => {
      return (
        <button
          className={classNames({
            [`${styles.filter_button}`]: true,
            [`${styles.filter_button_active}`]: sellerStatus === sellerFilter,
          })}
          onClick={() => {
            setCurrentPage(0);
            setSellerStatus(sellerFilter);
          }}
        >
          {t(`${capitalize(sellerFilter)}`)}
        </button>
      );
    });

    return content;
  };

  return (
    <AppMainLayout navBarTitle={t("Sellers")}>
      <div className={styles.customer_page_bottom_container}>
        <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
          <InputLabel htmlFor="outlined-adornment-amount">
            {t("Search")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<SearchIcon />}
            label={t("Search")}
            value={searchText}
            onChange={(e) => {
              setCurrentPage(0);
              setSearchText(e.target.value);
            }}
          />
        </FormControl>

        <div
          className={classNames({
            [styles.info_block_input_container]: true,
          })}
        >
          <FormControl fullWidth>
            <Autocomplete
              id="country-select-demo"
              options={merchatsList}
              autoHighlight
              getOptionLabel={(option) => option.id && `#${option.id} ${option.merchantName}`}
              value={selectedMerchant || { id: "" }}
              onChange={(event, newValue) => {
                setTimeout(() => {
                  setCurrentPage(0);
                }, 700);
                setSelectedMerchant(newValue);
              }}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  #{option.id} {option.merchantName}
                </Box>
              )}
              className={styles.autocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Merchant_id")}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </FormControl>
        </div>

        <Link to="/app/sellers/new" className={styles.no_underline_add_button}>
          <Button
            variant="primary"
            icon={<PlusIcon />}
            label={t("New_seller")}
          />
        </Link>
      </div>

      <div className={styles.calendar_and_filters_container}>
        <div className={styles.filter_container}>{renderFilterItems()}</div>
      </div>

      <DataTableComponent
        // onCellClick={(row) => onCellClick(row)}
        checkboxSelection={false}
        columns={renderLicensesCells()}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.sellers_table}
        {...{ rows }}
      />
      {!isFetching && (
        <Pagination
          onClick={paginationClick}
          onChangePageSize={pageSizeClick}
          total={totalCount}
          pageIndex={currentPage}
          pageSize={pageSize}
        />
      )}
    </AppMainLayout>
  );
};

export default Sellers;
