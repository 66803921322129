/* eslint-disable no-throw-literal */
import Store from "../store";
import axios from "axios";
import moment from "moment";
import {
  storeToken,
  storeRefreshToken,
  storeExpiredTime,
  removeToken,
  removeUserData,
  removeExpiredTime,
  removeRefreshToken,
} from "../services/Auth";

Store.subscribe(listener);

// function select(state) {
//     return state.TokenReducer;
// }

function listener() {
  //const token = isTokenPresent() ? getToken() : "";
  //console.log("+++++++++++++++++++++++++++ ", token, "///", token?.length)
  // const token = select(Store.getState()) || null;
  // if (token) {
  //     axios.defaults.headers.common['Authorization'] = "Bearer " + token;
  // }
}

let isRefreshing = false;
let refreshSubscribers = [];


const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
};

const onErrorRefresh = () => {
  refreshSubscribers.forEach((cb) => cb(null)); // Передаем null или ошибку
  refreshSubscribers = [];
}


axios.defaults.baseURL = process.env.PUBLIC_URL;

const apiMethod = (config, token) => {
  if (
    config.url !== "/sap/api/v1/auth/refreshToken" &&
    config.url !== "/sap/api/v1/auth/login" &&
    config.url !== "/sap/api/v1/auth/logout"
  ) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = ``;
  }
  return config;
};

// to do two tabs - refresh token

axios.interceptors.request.use(
  async (config) => {
    // const sessionToken = localStorage.getItem("_session_token");
    // const rToken = localStorage.getItem("_refresh_token");
    const expTime = localStorage.getItem("_expired_time");

    const now = moment();
    const secDiff = now.diff(expTime, "seconds");

    if (
      config.url === "/sap/api/v1/auth/refresh" ||
      config.url === "/sap/api/v1/auth/login"
    ) {
      config.headers.Authorization = ``;
      return config;
    }

    if (config.url === "/sap/api/v1/file") {
      config.headers['Content-type'] = `multipart/form-data`;
      // config.headers.Authorization = `Bearer ${sessionToken}`;
      // return config;
    }

    const diff = secDiff * -1 < 10;
    // const newToken = sessionToken;

    if (diff && !isRefreshing) {
      isRefreshing = true;
      const rToken = localStorage.getItem("_refresh_token");
      try {
        const params = {
          refreshToken: rToken,
        };
        const response = await axios.post("/sap/api/v1/auth/refresh", {
          ...params,
        });
        const {
          data: { accessToken, accessTokenExpiresIn, refreshToken },
        } = response;
        storeToken(accessToken);
        storeRefreshToken(refreshToken);
        const updatedDate = moment().add(accessTokenExpiresIn, "seconds");
        storeExpiredTime(updatedDate);

        isRefreshing = false;
        onRefreshed(accessToken);

        return apiMethod(config, accessToken);
      } catch (error) {
        isRefreshing = false;
        onErrorRefresh();
        // TO DO clear to check no internet problem   refreshSubscribers
        removeExpiredTime();
        removeRefreshToken();
        removeToken();
        removeUserData();
        return Promise.reject(error);
      }
    } else if (isRefreshing) {
      return new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          if (token) {
            resolve(apiMethod(config, token));
          } else {
            reject('Token error');
          }
        });
      });
    }
    const sessionToken = localStorage.getItem("_session_token");
    return apiMethod(config, sessionToken);
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response?.data?.accessToken) {
      storeToken(response?.data.accessToken);
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      removeExpiredTime();
      removeRefreshToken();
      removeToken();
      removeUserData();
      //window.location.reload();
    }
    return error;
  }
);

export default axios;

export const apiRequest = async (method, url, params) => {
  let status = null;

  try {
    const response = await axios({
      method,
      url,
      data: params,
      validateStatus: function (s) {
        status = s;
        console.log("status", status);
        return status >= 200 && status < 300;
      },
    });

    return { status, response };
  } catch (error) {
    console.log("error", error);
    if (error.response) {
      throw { status: error.response.status, data: error.response.data };
    } else {
      throw error;
    }
  } finally {
    console.log("Final status:", status);
  }
};