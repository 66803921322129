import React, { useState, useEffect, useCallback } from 'react';
import { ISignInPageContent } from '../../interfaces';
import Heading from '../../infrastructure/components/Heading'
import FormLabeledInput from '../../infrastructure/components/FormComponents/FormLabeledInput';
import ButtonComponent from '../../infrastructure/components/ButtonComponent';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, authStart, authEnd, setToken, setExpiredTime, setTokenInHeaders, setTokenExpired } from '../../api/actionCreators/authActionCreator';
import { getIsAuthFetching } from '../../api/selectors/authSelector'
import { getRefreshToken, getAccessToken, getTokenInHeaders } from '../../api/selectors/tokenSelector'
import CircularProgress from '@mui/material/CircularProgress';
import { Redirect } from 'react-router-dom';
import axios from "../../api/services/Api";
import { storeToken, storeUser, storeExpiredTime, storeRefreshToken } from "../../api/services/Auth";
import { Logo } from '../../assets/icons/logo';
import moment from "moment"
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { PersonIcon, KeyIcon, EyeIcon, LanguageIcon } from "finergy-kit"
import { getCurrentLanguage } from '../../api/selectors/languageSelector';
import { changeLanguage } from '../../api/actionCreators/mainActionCreator';
import { useTranslation } from 'react-i18next';
import { VALIDATION } from "../../constants"
// import { createServer } from 'miragejs';
// import authHandler from '../../api/miragejs/handlers/authHandler';
import styles from './signin-page.module.scss';
// const config = require('../../config.json');


// createServer({
//     routes() {
//         authHandler(this, "http://localhost:3000/")
//         this.passthrough()
//     }
// })

const SignInPageContent = (props: ISignInPageContent) => {

    const dispatch = useDispatch();

    const isAuthFetching = useSelector(getIsAuthFetching)
    const token = useSelector(getRefreshToken)
    const accessToken = useSelector(getAccessToken)
    //const data = useSelector(getAuthData);
    const language = useSelector(getCurrentLanguage);
    const tokenInHeader = useSelector(getTokenInHeaders)

    const { t } = useTranslation();

    // const [login, setLogin] = useState("")
    // const [password, setPassword] = useState("")
    const [formData, setFormData] = useState({ login: "", password: "" })
    const [error, setError] = useState("")
    const [invalidFields, setInvalidFields] = useState([])
    const [redirect, setRedirect] = useState(false)
    const [currentLanguage, setcurrentLanguage] = useState(language);

    useEffect(() => {
        setInvalidFields([])
    }, [formData])


    const validateInput = useCallback(() => {
        const result = [];
        VALIDATION.SIGN_IN_FORM.forEach(field => {
            !formData[field] && result.push(field);
        })
        return result.length > 0 ? result : null;
    }, [formData]);

    const onLogin = useCallback(() => {

        const invalidFields = validateInput();
        setInvalidFields(invalidFields)
        setError("")
        
        const { login, password } = formData
        const params = {
            username: login,
            password
        }
        !invalidFields && dispatch(authStart())
        !invalidFields && axios.post("/sap/api/v1/auth/login", { ...params })
            .then((res: any) => {
                const { data: { accessToken, accessTokenExpiresIn, refreshToken } } = res
                // console.log("res.status", res)
                if (res.status === 200 || res.status === 201) {
                    const tokenData = {
                        accessToken,
                        accessTokenExpiresIn,
                        refreshToken,
                    }
                    storeToken(accessToken)
                    storeRefreshToken(refreshToken)
                    const updatedDate = moment().add(accessTokenExpiresIn, 'seconds');
                    storeExpiredTime(updatedDate)
                    storeUser(JSON.stringify({ login: formData.login }))

                    dispatch(signIn(JSON.stringify({ login: formData.login })))
                    dispatch(setToken(tokenData))
                    dispatch(setExpiredTime(updatedDate.toDate()))
                    dispatch(authEnd())
                    dispatch(setTokenInHeaders(true))
                    dispatch(setTokenExpired(false))
                    setRedirect(true)
                    //window.location.reload()

                } else {
                    setError("Wrong_details")
                    dispatch(authEnd())
                }
            })
            .catch((error) => {
                setError("Wrong_details")
                dispatch(authEnd())
            });

    }, [dispatch, formData, validateInput]);

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.code === 'Enter' && !tokenInHeader) {
                event.preventDefault();
                onLogin()
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [formData, onLogin, tokenInHeader])

    useEffect(() => {
        token && accessToken && setRedirect(true)
    }, [token, accessToken])

    const handleLanguageChange = (lang) => {
        setcurrentLanguage(lang)
        dispatch(changeLanguage(lang));
    };

    const onChange = (state, value) => {
        setFormData({
            ...formData,
            [state]: value
        })
    }

    return (
        <div className={styles.mainLoginSection}>
            {redirect && <Redirect to="/app/merchants" />}
            <div className={styles.grid_wrapper}>

                <div className={styles.bg_image_container}>
                    <div className={styles.blur_bg}></div>
                    <Logo />
                </div>

                <div className={styles.signin_container}>
                    <div className={styles.main_container}>
                        <div className={styles.select_lang_container_parent}>
                            <FormControl className={styles.select_lang_container}>
                                {/* <InputLabel id="demo-simple-select-label">{t('Employment')}</InputLabel> */}
                                <LanguageIcon />
                                <Select
                                    className="select-lang-selector"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={currentLanguage}
                                    onChange={(e) => handleLanguageChange(e.target.value)}
                                >
                                    <MenuItem value={"ro"} key={0}>RO</MenuItem>
                                    <MenuItem value={"ru"} key={1}>RU</MenuItem>
                                    <MenuItem value={"en"} key={2}>EN</MenuItem>
                                </Select>
                            </FormControl>
                        </div>


                        <Heading
                            textCentered
                            headingFirst={t('Authentification')}
                        />
                        <Heading
                            textRegular={t('Fill_field_above')}
                            className={`${styles.mb_24} ${styles.mt_5} `}
                            textCentered
                        />
                        <FormLabeledInput
                            label={t("Login")}
                            type="text"
                            value={formData.login}
                            id={"LOGIN"}
                            iconLeft={<PersonIcon />}
                            onFocus={() => setError("")}
                            onChange={(e) => onChange("login", e.target.value)}
                            error={invalidFields?.length > 0 && invalidFields.findIndex(i => i === "login") > -1}
                        />
                        <FormLabeledInput
                            type="password"
                            value={formData.password}
                            onFocus={() => setError("")}
                            iconLeft={<KeyIcon />}
                            iconRight={<EyeIcon />}
                            onChange={(e) => onChange("password", e.target.value)}
                            label={t('Password')}
                            showPasswordText
                            id={"PASSWORD"}
                            error={invalidFields?.length > 0 && invalidFields.findIndex(i => i === "password") > -1}
                        />
                        {/* <div className={styles.forget_password}>{t('Forget_password')}</div> */}
                        <ButtonComponent
                            title={t('Log_in')}
                            onClick={() => onLogin()}
                            disabled={isAuthFetching}
                            icon={<CircularProgress className="circular-progress" size={18} />}
                        />


                        {error && !invalidFields && <div className={styles.error_container}>{t(error)}</div>}
                    </div>
                    <div className={styles.copyright}>
                        © 2024 miaPOS. {t("All_rights_reserved")}.
                    </div>

                </div>

            </div>
        </div>
    );
};
export default SignInPageContent