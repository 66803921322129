/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import FormControl from "@mui/material/FormControl";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  getRefreshToken,
  getAccessToken,
} from "../../api/selectors/tokenSelector";
import Button from "@mui/material/Button";
import { PlayIcon } from "../../svg-icons/icon-play";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { getCurrentLanguage } from "../../api/selectors/languageSelector";
import { FORMAT } from "../../constants";
import { enqueueSnackbar } from "notistack";
import { apiRequest } from "../../api/services/Api";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en";
import "dayjs/locale/ro";
import "dayjs/locale/ru";
import dayjs from "dayjs";
import { downloadFile } from "../../hooks/downloadFile"

import styles from "./reports.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Reports = () => {
  const { t } = useTranslation();

  const reportDefault = {
    merchantId: null,
    startDate: dayjs().startOf("day").format(FORMAT),
    endDate: dayjs().endOf("day").format(FORMAT),
  };

  const [isAdding, setIsAdding] = useState(false);
  const [showReportModalModal, setShowReportModalModal] = useState(false);

  const [merchatsList, setMerchatsList] = useState([]);
  const [reportData, setReportData] = useState({ ...reportDefault });
  const [invalidFields, setInvalidFields] = useState([]);

  const language = useSelector(getCurrentLanguage);
  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    if (showReportModalModal) {
      const getMerchatsList = async () => {
        try {
          const { status, response } = await apiRequest(
            "get",
            `/sap/api/v1/merchant/brief`,
            null
          );

          if (status === 200 || status === 201) {
            const { data } = response;
            setMerchatsList(data.merchantList);
          } else if (status === 400) {
            enqueueSnackbar(t("Error_400"), { variant: "error" });
          } else if (status === 401) {
            enqueueSnackbar(t("Error_401"), { variant: "error" });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else if (status === 404) {
            enqueueSnackbar(t("Error_404"), { variant: "error" });
            setTimeout(() => {
              setShowReportModalModal(false);
            }, 3000);
          } else if (status === 500) {
            enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
            setTimeout(() => {
              setShowReportModalModal(false);
            }, 3000);
          } else if ([501, 502, 503, 504, 505].includes(status)) {
            enqueueSnackbar(t("Error_500"), { variant: "error" });
            setTimeout(() => {
              setShowReportModalModal(false);
            }, 3000);
          }
        } catch (error) {
          console.error("Failed to fetch merchants list:", error);
        }
      };
      getMerchatsList();
    }
  }, [showReportModalModal]);

  console.log("reportData", reportData);
  const onGetReport = async () => {
    setIsAdding(true);

    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/report/commission?merchantId=${reportData.merchantId?.id || ""}&dateFrom=${reportData.startDate}&dateTo=${reportData.endDate}`,
        null
      );
      console.log("response", response)
      // downloadFile()


      if (status === 200 || status === 201) {
        console.log("response.data.result", response.data.result)
        if (response.data.result === "success") {
          const date = moment().format("DD-MM-YYYY--HH-mm");
          const encoded = btoa(response.data.report);
          downloadFile(encoded, `report-${date}`, "csv");
          enqueueSnackbar(t("Download_started"), { variant: "success" });
          setReportData({ ...reportDefault });
          setIsAdding(false);
          setShowReportModalModal(false);
        } else if (response.data.result === "merchant_not_found") {
          enqueueSnackbar(t("Merchant_not_found"), { variant: "error" });
        } else if (response.data.result === "no_data") {
          enqueueSnackbar(t("No_data"), { variant: "error" });
        } else if (response.data.result === "generation_error") {
          enqueueSnackbar(t("Generation_error"), { variant: "error" });
        }


      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch merchants:", error);
    } finally {
      setIsAdding(false);
    }
  };

  const onChange = (state, value) => {
    const dateValue = state === "startDate" ? dayjs(value).startOf("day").format(FORMAT) : state === "endDate" ? dayjs(value).endOf("day").format(FORMAT) : value
    console.log("dateValue", dateValue)
    const data = {
      ...reportData,
      [state]: dateValue,
    };
    console.log("data", data)
    setReportData(data);
  };

  const removeError = (stateName) => {
    if (invalidFields && invalidFields.length > 0) {
      const fIndex =
        invalidFields && invalidFields.findIndex((e) => e === stateName);
      const errorListFields = [
        ...invalidFields?.slice(0, fIndex),
        ...invalidFields?.slice(fIndex + 1),
      ];

      setInvalidFields(errorListFields || []);
    }
  };

  const disabledButton = !reportData.startDate || !reportData.endDate;
  const renderAddnewCommisionModal = () => {
    return (
      <Dialog
        open={showReportModalModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowReportModalModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Service_fee_details_generation")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="Service_fee_details_generation"
              className={styles.logout_header}
            >
              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="merchantId">{t("Merchant_id")}</label>
                <FormControl fullWidth>
                  <Autocomplete
                    id="merchantId"
                    options={merchatsList}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.id && `#${option.id} ${option.merchantName}`
                    }
                    value={reportData.merchantId || null}
                    onChange={(event, newValue) => {
                      onChange("merchantId", newValue || null);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        #{option.id} {option.merchantName}
                      </Box>
                    )}
                    className={styles.autocomplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Merchant_id")}
                        onFocus={() => removeError("merchantId")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "merchantId") >
                          -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "merchantId") >
                          -1
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>

              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="merchantId">{t("Start_date")}</label>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={language ?? "ro"}
                >
                  <DatePicker
                    label={t("Start_date")}
                    value={dayjs(reportData.startDate)}
                    onChange={(newValue) => {
                      onChange("startDate", newValue || "");
                    }}
                    maxDate={dayjs(reportData.endDate) || dayjs()}
                    format={"DD/MM/YYYY"}
                    disablePast={false}
                    disableFuture
                  />
                </LocalizationProvider>
              </div>

              <div
                className={classNames({
                  [styles.info_block_input_container]: true,
                })}
              >
                <label htmlFor="merchantId">{t("End_date")}</label>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={language ?? "ro"}
                >
                  <DatePicker
                    label={t("End_date")}
                    value={dayjs(reportData.endDate)}
                    onChange={(newValue) => {
                      onChange("endDate", newValue || "");
                    }}
                    minDate={dayjs(reportData.startDate) || dayjs()}
                    format={"DD/MM/YYYY"}
                    disablePast={false}
                    disableFuture
                  />
                </LocalizationProvider>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() =>
                isAdding || disabledButton ? {} : onGetReport()
              }
              variant="contained"
              disabled={false}
              className={classNames({
                [`${styles.cancel_button}`]: true,
                [`${styles.is_disabled}`]: isAdding || disabledButton,
              })}
            >
              {t("Apply")}
              {isAdding && (
                <CircularProgress className="circular-progress" size={18} />
              )}
            </Button>
            <Button
              onClick={() => closeModal()}
              variant="text"
              className={styles.exit_button}
            >
              {t("Cancel")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const closeModal = () => {
    setReportData({ ...reportDefault });
    setShowReportModalModal(false);
  };

  return (
    <AppMainLayout navBarTitle={t("Reports")}>
      {renderAddnewCommisionModal()}

      <div
        className={styles.reports_container}
        onClick={() => setShowReportModalModal(true)}
      >
        <span className={styles.text_label}>{t("Services_fee_details")}</span>
        <div className={styles.icon_container}>
          <PlayIcon />
        </div>
      </div>
    </AppMainLayout>
  );
};

export default Reports;
