import React, { ChangeEvent, useState, useEffect } from "react";
import { ISettingsModalProps } from "./types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { CloseRemoveIcon } from "../../../../svg-icons/icon-close-remove";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { LanguageIcon } from "finergy-kit";
import { changeLanguage } from "../../../../api/actionCreators/mainActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { getSettingsState } from "../../../../api/selectors/mainSelector";
import CircularProgress from "@mui/material/CircularProgress";
import { getCurrentLanguage } from "../../../../api/selectors/languageSelector";
import axios from "../../../../api/services/Api";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

import styles from "./settings-modal.module.scss";
import { AttachFileIcon } from "../../../../svg-icons/icon-attach-file";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SettingsModal = ({
  showSettingsModal,
  setShowSettingsModal,
}: ISettingsModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const language = useSelector(getCurrentLanguage);
  const [activeTab, setActiveTab] = useState("common");
  const [bankName, setBankName] = useState(null);
  const [bankOldName, setBankOldName] = useState(null);
  const [image, setImage] = useState({
    src: null,
  });

  const [imageOld, setImageOld] = useState({
    src: null,
  });

  const [isSaveSettingsFetching, setIsSaveSettingsFetching] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const getSettings = () => {
    axios.get(`/sap/api/v1/bank`).then((res) => {
      const { data } = res;
      setBankName(data.bankInfo.bankName);
      setImage({ src: `data:image/jpeg;base64,${data.bankInfo.image}` });

      setBankOldName(data.bankInfo.bankName);
      setImageOld({ src: `data:image/jpeg;base64,${data.bankInfo.image}` });
      // dispatch(saveSettings(obj))
    });
  };

  useEffect(() => {
    getSettings();
    !showSettingsModal && setActiveTab("common");
  }, [showSettingsModal]);

  const editBankInfo = () => {
    const params = {
      bankName,
      image: image.src
        .replace("data:image/jpeg;base64,", "")
        .replace("data:image/png;base64,", "")
        .replace("data:image/webp;base64,", ""),
    };
    axios.put(`/sap/api/v1/bank`, { ...params }).then(() => {
      setIsSaveSettingsFetching(false);
      setShowSettingsModal(false);
    });
  };

  const handleLanguageChange = () => {
    if (bankName.startsWith(" ")) {
      return;
    }
    setIsSaveSettingsFetching(true);
    dispatch(changeLanguage(selectedLanguage));
    if (bankOldName !== bankName || image.src !== imageOld.src) {
      editBankInfo();
    } else {
      setShowSettingsModal(false);
      setIsSaveSettingsFetching(false);
    }
  };

  const resetSettingsData = () => {
    setActiveTab("common");
    setShowSettingsModal(false);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    const getBase64 = (file, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

    getBase64(file, (result) => {
      setImage({
        // name: file.name,
        src: result,
      });
    });
  };
  const onFileUpload = (event) => {
    event && handleFileUpload(event);
  };

  const isSomeChanges =
    selectedLanguage === language &&
    ((bankOldName === bankName && image.src === imageOld.src) ||
      !bankName ||
      !image.src ||
      bankName.startsWith(" "));

  const renderMainDialogContent = () => {
    return (
      <Dialog
        open={showSettingsModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowSettingsModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          className={classNames({
            [`${styles.custom_modal_container}`]: true,
            [`${styles.custom_modal_container_min_height}`]: true,
          })}
        >
          <CloseRemoveIcon
            className={styles.close_icon}
            onClick={() => setShowSettingsModal(false)}
          />
          <DialogTitle className={styles.setting_modal_title}>
            {t("Settings")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <div className={styles.tabs_content}>
              <div
                className={classNames({
                  [`${styles.tab_content_item}`]: true,
                  [`${styles.active_content}`]: activeTab === "common",
                })}
              >
                <div className={styles.settings_block}>
                  <div className={styles.settings_block_title}>
                    {t("Language")}
                  </div>
                  <div className={styles.settings_block_content}>
                    <div className={styles.settings_block_description}>
                      <div className={styles.settings_content_block_title}>
                        {t("System_language")}
                      </div>

                      <div
                        className={classNames({
                          [`${styles.description_action}`]: true,
                          [`${styles.select_lang_container_parent}`]: true,
                        })}
                      >
                        <FormControl className={styles.select_lang_container}>
                          <LanguageIcon />
                          <Select
                            className="select-lang-selector"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedLanguage}
                            onChange={(e) =>
                              setSelectedLanguage(e.target.value)
                            }
                          >
                            <MenuItem value={"ro"} key={0}>
                              RO
                            </MenuItem>
                            <MenuItem value={"ru"} key={1}>
                              RU
                            </MenuItem>
                            <MenuItem value={"en"} key={2}>
                              EN
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.settings_block}>
                  <div className={styles.settings_block_title}>
                    {t("Customize_your_pos")}
                  </div>
                  <div
                    className={classNames({
                      [`${styles.settings_block_content}`]: true,
                      [`${styles.no_padding}`]: true,
                    })}
                  >
                    <div
                      className={classNames({
                        [`${styles.settings_block_description}`]: true,
                        [`${styles.additional_padding}`]: true,
                      })}
                    >
                      <div className={styles.settings_content_block_title}>
                        {t("Bank_name")}
                      </div>
                      <div
                        className={classNames({
                          [`${styles.description_action}`]: true,
                          [`${styles.select_lang_container_parent}`]: true,
                        })}
                      >
                        <TextField
                          //value={count?.number}
                          value={bankName}
                          type="text"
                          onChange={(e) => setBankName(e.target.value || null)}
                        />
                      </div>
                    </div>
                    <div
                      className={classNames({
                        [`${styles.settings_block_description}`]: true,
                        [`${styles.additional_padding}`]: true,
                      })}
                    >
                      <div className={styles.settings_content_block_title}>
                        {t("Attach_logo")}
                      </div>
                      <div
                        className={classNames({
                          [`${styles.description_action}`]: true,
                          [`${styles.select_lang_container_parent}`]: true,
                        })}
                      >
                        <Button
                          component="label"
                          className={styles.attach_logo}
                        >
                          {t("Attach_file")}
                          <AttachFileIcon />
                          <input
                            type="file"
                            hidden
                            onChange={(e) => onFileUpload(e)}
                          />
                        </Button>
                      </div>
                    </div>

                    {image.src && (
                      <div
                        className={classNames({
                          [`${styles.settings_block_description}`]: true,
                          [`${styles.additional_padding}`]: true,
                          [`${styles.logo_background}`]: true,
                        })}
                      >
                        <div>
                          <img src={image.src} alt="" />
                        </div>

                        {image.src && (
                          <div className="image-info">
                            <CloseIcon
                              onClick={() => {
                                setImage({ src: "" });
                              }}
                              sx={{ color: "#606060", cursor: "pointer" }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            {activeTab === "common" && (
              <Button
                onClick={() =>
                  !isSomeChanges || !isSaveSettingsFetching
                    ? handleLanguageChange()
                    : {}
                }
                variant="contained"
                disabled={isSomeChanges || isSaveSettingsFetching}
                className={classNames({
                  [`${styles.cancel_button}`]: !isSomeChanges,
                  [`${styles.is_disabled}`]:
                    isSomeChanges || isSaveSettingsFetching,
                })}
              >
                {t("Apply")}
                {isSaveSettingsFetching && <CircularProgress size={20} />}
              </Button>
            )}
            <Button onClick={() => resetSettingsData()} variant="contained">
              {t("Cancel")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  return <>{renderMainDialogContent()}</>;
};

export default SettingsModal;
